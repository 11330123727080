<template>
    <div>
        <div class="flex justify-between mb-5 cursor-pointer">
            <a href="javascript:history.back()"
                ><i class="fas fa-arrow-left text-[25px]" title="Volver"
            /></a>
            <button
                @click="showModalPaymentMethod()"
                class="ml-auto mt-3 inline-flex w-full justify-center rounded-md bg-black text-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:mt-0 sm:w-auto"
            >
                Nuevo Plan
            </button>
        </div>
        <div class="w-full mb-5">
            <TableComponent
                id="plansTable"
                :rows="rows"
                :columns="columns"
                @draw="drawCallback"
                :custom-config="tableConfig"
            />
        </div>
        <ModalComponent
            :isOpen="isOpen"
            @closeModal="isOpen = false"
            id="planModal"
        >
            <template #header>
                <h4 class="font-bold mb-5">
                    {{ `${currentRow.id ? "Editar" : "Nuevo"} Plan` }}
                </h4>
            </template>
            <form class="w-full" @submit.prevent="onSubmit">
                <div>
                    <label
                        for="nombre"
                        class="block text-sm font-medium leading-6 text-gray-900"
                        >Nombre Plan</label
                    >
                    <div class="mt-2">
                        <input
                            id="nombre"
                            name="nombre"
                            required
                            autocomplete="off"
                            v-model="currentRow.nombre"
                            class="block w-full rounded-md border-0 py-1.5 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <div class="mt-4">
                    <label
                        for="cuotas"
                        class="block text-sm font-medium leading-6 text-gray-900"
                        >Cuotas</label
                    >
                    <div class="mt-2">
                        <input
                            id="cuotas"
                            name="cuotas"
                            required
                            autocomplete="off"
                            v-model="currentRow.cuotas"
                            class="block w-full rounded-md border-0 py-1.5 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <div class="mt-4">
                    <label
                        for="recargo"
                        class="block text-sm font-medium leading-6 text-gray-900"
                        >Recargo</label
                    >
                    <div class="mt-2">
                        <input
                            id="recargo"
                            name="recargo"
                            required
                            autocomplete="off"
                            v-model="currentRow.recargo"
                            class="block w-full rounded-md border-0 py-1.5 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <div class="mt-4 flex align-center gap-x-2">
                    <label
                        for="enabled"
                        class="block text-sm font-medium leading-6 text-gray-900"
                        >Habilitado</label
                    >
                    <div>
                        <input
                            id="enabled"
                            name="enabled"
                            type="checkbox"
                            :checked="currentRow.enabled"
                            v-model="currentRow.enabled"
                            class="py-1.5 pl-2 text-gray-900 shadow-sm placeholder:text-gray-400 sm:leading-6"
                        />
                    </div>
                </div>
                <div class="mt-4">
                    <label
                        for="imagen"
                        class="block text-sm font-medium leading-6 text-gray-900"
                        >Imagen</label
                    >
                    <div class="mt-2">
                        <input
                            id="imagen"
                            type="url"
                            pattern="https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$"
                            name="url_imagen"
                            autocomplete="off"
                            v-model="currentRow.url_imagen"
                            class="block w-full rounded-md border-0 py-1.5 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <input type="submit" hidden ref="inputSubmitRef" />
            </form>
            <template #buttons>
                <div class="flex justify-end gap-x-1 mr-6">
                    <div class="bg-gray-50 py-3">
                        <button
                            type="button"
                            class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            @click="isOpen = false"
                        >
                            Cerrar
                        </button>
                    </div>
                    <div class="bg-gray-50 py-3">
                        <button
                            type="submit"
                            @click="inputSubmitRef.click()"
                            class="mt-3 inline-flex w-full justify-center rounded-md bg-black text-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:mt-0 sm:w-auto"
                        >
                            Guardar
                        </button>
                    </div>
                </div>
            </template>
        </ModalComponent>
    </div>
</template>
<script setup>
import { ref, watch, onMounted } from "vue";
import Notiflix from "notiflix";
import TableComponent from "../components/TableComponent";
import ModalComponent from "../components/ModalComponent";

const props = defineProps({
    planes: {
        type: Array,
        default: () => [],
    },
    medio_de_pago_id: String,
});

const buttons = [
    {
        title: "Report",
        extend: "csvHtml5",
        text: "CSV",
        className: "bg-black text-white rounded p-2 text-sm",
    },
];

const inputSubmitRef = ref();
const currentRow = ref({});
const tableRef = ref();
const isOpen = ref(false);
const rows = ref([]);
const tableConfig = ref({
    order: [[0, "asc"]],
    select: {
        style: "multi",
        selector: "td:first-child",
    },
    buttons,
    rowId: "id",
    // initComplete: function (settings, json) {
    //   console.log('DataTables has finished its initialisation.', settings, json);
    // }
});

const columns = ref([
    { data: "id", title: "ID", className: "dt-center" },
    { data: "nombre", title: "Nombre", className: "dt-center" },
    { data: "cuotas", title: "Cant. Cuotas", className: "dt-center" },
    { data: "recargo", title: "Recargo", className: "dt-center" },
    {
        data: "url_imagen",
        title: "Imagen",
        className: "dt-center",
        render: (url_imagen, _, row) => {
            if (!url_imagen) return "";
            return `<img src='${url_imagen?.startsWith("http") ? url_imagen : `../${url_imagen}`}' style='width: 30px; heigth: 30px; margin-inline: auto' role='button' id='${row.id}' />`;
        },
    },
    {
        data: "enabled",
        title: "Habilitado",
        className: "dt-center",
        render: (enabled) => (enabled ? "Si" : "No"),
    },
    {
        data: null,
        title: "Acciones",
        className: "dt-center",
        render: (data) => {
            return `<div class='flex justify-center gap-2'>
        <i class="fas fa-edit text-indigo-600" role='button' id="edit" data-id="${data.id}"></i>
        <i class="fas fa-trash-alt text-red-600" role='button' id="delete" data-id="${data.id}"></i>
      </div>`;
        },
    },
]);

const drawCallback = (table) => (tableRef.value = table.dt.value);

const deletePaymentMethod = async (id) => {
    try {
        await window.axios.delete(`/api/planes/${id}`);
        rows.value = rows.value.filter(({ id: rowId }) => rowId != id);
        Notiflix.Notify.success("Plan de Pago Eliminado");
    } catch {}
};

const showModaldeletePaymentMethod = (idPlan) => {
    Notiflix.Confirm.show(
        "¿Estas seguro/a?",
        "Borrar Plan de Pago",
        "Borrar",
        "Cancelar",
        () => deletePaymentMethod(idPlan),
        () => {},
    );
};

const showModalPaymentMethod = (idPlan) => {
    isOpen.value = true;
    if (idPlan) {
        const row = tableRef.value.row(`#${idPlan}`).data();
        currentRow.value = JSON.parse(JSON.stringify(row));
    } else currentRow.value = {};
};

const onSubmit = () => {
    if (!currentRow.value.id) {
        createPlan();
        return;
    }
    editPlan();
};

const createPlan = async () => {
    try {
        Notiflix.Block.dots("#planModal", "Creando plan de pago");
        const {
            data: { result },
        } = await window.axios.post("/api/planes", {
            ...currentRow.value,
            medio_de_pago_id: props.medio_de_pago_id,
        });
        rows.value = [...rows.value, result];

        currentRow.value = {};
        isOpen.value = false;
        Notiflix.Notify.success("Plan de Pago Creado");
    } catch {
        Notiflix.Notify.failure("Error al intentar crear plan de pago");
    } finally {
        Notiflix.Block.remove("#planModal");
    }
};

const editPlan = async () => {
    try {
        Notiflix.Block.dots("#planModal", "Editando plan de pago");
        await window.axios.put("/api/planes", currentRow.value);
        const row = tableRef.value.row(`#${currentRow.value.id}`);
        isOpen.value = false;
        Notiflix.Notify.success("Plan de Pago Editado");
        row.data(currentRow.value).draw();
        currentRow.value = {};
    } catch {
        Notiflix.Notify.failure("Error al editar plan de pago");
    } finally {
        Notiflix.Block.remove("#planModal");
    }
};

watch(tableRef, (table) => {
    table
        .table()
        .on("click", "#delete", (evt) =>
            showModaldeletePaymentMethod(evt.target.dataset.id),
        );
    table
        .table()
        .on("click", "#edit", (evt) =>
            showModalPaymentMethod(evt.target.dataset.id),
        );
});

onMounted(() => {
    rows.value = props.planes;
});
</script>

<style>
.dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 8px 16px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    background-color: black !important;
    color: white !important;
    border: 0;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    background-color: gray !important;
    color: black !important;
}

.dataTables_wrapper .dataTables_filter {
    margin-bottom: 30px !important;
}

.dataTables_wrapper table thead {
    background-color: black !important;
    color: white !important;
}

.dataTables_filter input {
    outline: none;
    border: 1px solid gray !important;
}
</style>
