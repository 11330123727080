----------------------------TEMPLATE---------------------------------
<template>
    <div class="flex flex-col items-center justify-center w-full px-8 sm:max-w-xl bg-white pb-0">
        <h3 class="pt-3 self-start" style="
        font-size: 12px;
        font-weight: 900;
        line-height: 15.34px;
        text-align: left;
        color: #121c26;
      ">
            FINANCIACIÓN
        </h3>
        <div class="w-full mt-2 bg-white" style="box-shadow: 2px 2px 6.7px 0px rgba(0, 0, 0, 0.1)">
            <div class="max-w-screen-xl py-3 w-full mt-3 px-6">
                <div class="flex flex-col items-center container justify-center w-full">
                    <p v-if="isLoading" style="background: grey; width: 100%"
                        class="animate-pulse text-center bg-gray-200 rounded-full dark:bg-gray-700 max-w-[100%] h-[40px] mb-2.5">
                    </p>
                    <div v-if="!isLoading && planRecargoCero.cuotas"
                        class="flex py-2 gap-1 justify-between w-full items-center">
                        <div class="flex shadow-black w-[34px] h-[19px]" style="box-shadow: 2px 2px 4px 0px #00000040">
                            <img class="w-full h-full object-contain" :src="planRecargoCero.url_imagen.startsWith('http')
                                ? planRecargoCero.url_imagen
                                : getImages(planRecargoCero.url_imagen)
                                " alt="" />
                        </div>
                        <div class="flex gap-1 items-center text-center text-[13px]" style="font-weight: 400;">
                            <span style="font-weight: 700;"> {{ planRecargoCero.cuotas }}</span>{{
                                !!planRecargoCero.cuotas && Number(planRecargoCero.cuotas) > 1
                                    ? "cuotas"
                                    : "cuota"
                            }}
                            <span style="font-weight: 700;">sin interés</span> de
                            <span class=" text-black" style="font-weight: 700;">${{
                                Intl.NumberFormat("es-AR", {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                }).format(
                                    parseFloat(
                                        (props.montoAFinanciar * (1 + planRecargoCero.recargo / 100)) /
                                        planRecargoCero.cuotas
                                    ).toFixed(2)
                                )
                            }}</span>
                        </div>
                    </div>
                    <p v-if="isLoading" style="background: grey; width: 100%"
                        class="animate-pulse text-center bg-gray-200 rounded-full dark:bg-gray-700 max-w-[100%] h-[40px] mb-2.5">
                    </p>

                    <div v-if="!isLoading && planRecargoMasBajo.length"
                        class="flex py-1.5 gap-1 justify-between w-full items-center">
                        <div class="flex shadow-black w-[34px] h-[19px]"
                            style="box-shadow: 2px 2px 4px 0px #00000040 ;">
                            <img class="w-full h-full object-contain" :src="planRecargoMasBajo?.[0].url_imagen.startsWith('http')
                                ? planRecargoMasBajo?.[0].url_imagen
                                : getImages(planRecargoMasBajo?.[0].url_imagen)
                                " alt="" />
                        </div>
                        <div class="flex gap-1 items-center text-center text-[13px]" style="font-weight: 400;">
                            <span style="font-weight: 700;">{{ planRecargoMasBajo?.[0].cuotas }}</span>{{
                                !!planRecargoMasBajo?.[0].cuotas &&
                                    Number(planRecargoMasBajo?.[0].cuotas) > 1
                                    ? "cuotas"
                                    : "cuota"
                            }}
                            <span style="font-weight: 700;">con interés</span> de
                            <span class="font-weight-bold text-black" style="font-weight: 700;">${{
                                Intl.NumberFormat("es-AR", {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                }).format(
                                    parseFloat(
                                        (props.montoAFinanciar *
                                            (1 + planRecargoMasBajo?.[0].recargo / 100)) /
                                        planRecargoMasBajo?.[0].cuotas
                                    ).toFixed(2)
                                )
                            }}</span>
                        </div>
                    </div>
                    <div v-if="
                        !isLoading &&
                        !Object.keys(planRecargoCero).length &&
                        planRecargoMasBajo.length > 1" class="flex py-1.5 gap-1 justify-between w-full items-center">
                        <div class="flex shadow-black w-[34px] h-[19px]"
                            style="box-shadow: 2px 2px 4px 0px #00000040 ;">
                            <img class="w-full h-full object-contain" :src="planRecargoMasBajo?.[1].url_imagen.startsWith('http')
                                ? planRecargoMasBajo?.[1].url_imagen
                                : getImages(planRecargoMasBajo?.[1].url_imagen)
                                " alt="" />
                        </div>
                        <div class="flex gap-1 items-center text-center text-[13px]" style="font-weight: 400;">
                            <span style="font-weight: 700;">{{ planRecargoMasBajo?.[1].cuotas }}</span>{{
                                !!planRecargoMasBajo?.[1].cuotas &&
                                    Number(planRecargoMasBajo?.[1].cuotas) > 1
                                    ? "cuotas"
                                    : "cuota"
                            }}
                            <span style="font-weight: 700;">con interés</span> de
                            <span class="font-weight-bold text-black" style="font-weight: 700;">${{
                                Intl.NumberFormat("es-AR", {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                }).format(
                                    parseFloat(
                                        (props.montoAFinanciar *
                                            (1 + planRecargoMasBajo?.[1].recargo / 100)) /
                                        planRecargoMasBajo?.[1].cuotas
                                    ).toFixed(2)
                                )
                            }}</span>
                        </div>
                    </div>
                    <div class="flex justify-center pt-4 text-blue-500 text-sm peer">
                        <button v-if="!isLoading" class="text-center group w-full text-[8px] text-[#225199]"
                            style="line-height:10.22px" @click="isOpenModalPlanes = !isOpenModalPlanes">
                            {{ isOpenModalPlanes ? "CERRAR" : "VER MAS" }}
                            <span class="flex justify-center text-[#225199]"><svg style="pointer-events: none"
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor"
                                    class="w-5 h-5 animate-pulse transition group-active:rotate-180">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5" />
                                </svg>
                            </span>
                        </button>
                    </div>
                </div>
                <div v-if="isOpenModalPlanes" v-for="(item, index) in dataCuotif" :key="index"
                    class="grid divide-y divide-neutral-200 max-w-md mx-auto mt-5 w-full">
                    <div class="pb-5 flex justify-between" style="border-bottom: 1px solid white">
                        <details class="group cursor-pointer w-full" :open="isOpened == item.id" :ref="'ref' + index"
                            @click.prevent="toggleDetail($event, item.id)">
                            <summary class="flex justify-between items-center font-medium cursor-pointer list-none"
                                style="pointer-events: none">
                                <div style="display: flex; gap: 8px; pointer-events: none; align-items: center">
                                    <img width="30" height="30" :src="`${item.url_imagen.startsWith('http')
                                        ? item.url_imagen
                                        : getImages(item.url_imagen)
                                        }`" :alt="`img-${item.nombre}`" class="me-1" />
                                    <span class="text-bold">{{ item.nombre }} </span>
                                </div>
                                <span class="transition group-open:rotate-180">
                                    <svg fill="none" height="24" shape-rendering="geometricPrecision"
                                        stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="1.5" viewBox="0 0 24 24" width="24">
                                        <path d="M6 9l6 6 6-6"></path>
                                    </svg>
                                </span>
                            </summary>
                            <div style="display: flex; justify-content: center; gap: 10px"
                                class="text-neutral-600 mt-5">
                                <div style="border: 1px solid rgb(230, 230, 230); border-radius: 10px"
                                    class="flex flex-col justify-center group-open:animate-fadeIn transition-all group-open:duration-500 w-full px-3">
                                    <div v-for="(tipo, index) in item.cuotificacion" :key="index"
                                        style="border-bottom: 1px solid rgb(230, 230, 230)"
                                        class="flex gap-5 justify-between items-center">
                                        <div class="flex items-center">
                                            <img :src="`${tipo.url_imagen.startsWith('http')
                                                ? tipo.url_imagen
                                                : getImages(tipo.url_imagen)
                                                }`" alt="" class="h-[30px] w-[30px] object-contain" />
                                            <p class="text-green-500 text-base pl-1">
                                                {{ tipo.cuotas }}
                                                {{
                                                    !!tipo.cuotas && Number(tipo.cuotas) > 1 ? "cuotas" : "cuota"
                                                }}
                                            </p>
                                        </div>
                                        <div class="py-3">
                                            <span class="font-semibold text-gray-700">
                                                ${{
                                                    Intl.NumberFormat("es-AR", {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0,
                                                    }).format(
                                                        parseFloat(
                                                            (props.montoAFinanciar * (1 + tipo.recargo / 100)) /
                                                            tipo.cuotas
                                                        ).toFixed(2)
                                                    )
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </details>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

---

-------------------------SCRIPT-----------------------------------
<script setup>
import { ref, onMounted, watch } from "vue";
import { initFlowbite, initAccordions } from "flowbite";

const props = defineProps({
    isOpen: {
        default: false,
        type: Boolean,
    },
    montoAFinanciar: {
        type: String,
    },
    isLoading: {
        type: Boolean,
    },
});

const isOpened = ref(null);
const isOpenModalPlanes = ref(false);
const dataCuotif = ref();
const planRecargoCero = ref({});
const planRecargoMasBajo = ref([]);
const unit = ref("");

onMounted(async () => {
    const params = new URLSearchParams(window.location.search);
    unit.value = params.get("unit");
    initAccordions();
    initFlowbite();
    await getMDP();
});

const toggleDetail = (e, id) => {
    isOpened.value = id == isOpened.value ? null : id;
};

const getMDP = async () => {
    try {
        const { data } = await window.axios.get("/api/cuotificacion", {
            params: {
                unit: unit.value,
            },
        });

        const { result } = data;
        dataCuotif.value = result;
    } catch {}
};

watch(dataCuotif, (cuotas = []) => {
    if (!cuotas.length) return;

    const planesArrayFormatted = cuotas.flatMap(
        (
            el, //funciona con la data local ver con json data
        ) =>
            el.cuotificacion.map((plan) => ({
                medioPago: el.nombre,
                logoMedioPago: el.url_imagen,
                ...plan,
                url_imagen: plan.url_imagen,
            })),
    );

    const planesRecargoCero = planesArrayFormatted.filter(
        (plan) => parseFloat(plan.recargo) == 0,
    );

    const planesRecargoDistintoCero = planesArrayFormatted.filter(
        (plan) => parseFloat(plan.recargo) !== 0,
    );

    const [planRecargoCeroYCuotaAlta] = planesRecargoCero.sort(
        (a, b) => b.cuotas - a.cuotas,
    );

    const [
        planRecargoBajoYCuotaAlta = {},
        secondPlanRecargoBajoYCuotaAlta = {},
    ] = planesRecargoDistintoCero.sort((a, b) => b.cuotas - a.cuotas);

    planRecargoCero.value = planRecargoCeroYCuotaAlta || {};
    planRecargoMasBajo.value = [
        planRecargoBajoYCuotaAlta,
        secondPlanRecargoBajoYCuotaAlta,
    ];
});

const getImages = (path) => {
    // const image = require(`../../assets/${path.replace('img', 'images')}`).default;
    const image = `/${path}`;

    return image;
};
</script>

----------------------------STYLE-----------------------------------
<style scoped>
.scrollP::-webkit-scrollbar {
    background-color: red;
    color: rgb(68, 255, 0);
}

.scrollP::-webkit-scrollbar {
    height: 8px;
    background-color: rgb(215, 215, 215);
    color: rgb(242, 255, 0);
    /* border-radius: 100%; */
}

.scrollP::-webkit-scrollbar-thumb {
    width: 1px;
    background-color: rgb(0, 0, 0);
    color: rgb(242, 255, 0);
    border-radius: 10%;
}
</style>
