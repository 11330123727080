@import "datatables.net-dt";
@import url("https://cdn.datatables.net/1.13.7/css/jquery.dataTables.min.css");
@import "tailwindcss/base";
/* @layer base {
  img {
    @apply inline-block;
  }
} */
@import "tailwindcss/components";
@import "tailwindcss/utilities";


*::-webkit-scrollbar-thumb {
  background: rgb(31 41 55 / 1);
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

*::-webkit-scrollbar {
  width: 16px;
}

/* src/assets/styles/assets.css */
@font-face {
  font-family: "Mont";
  src: url("../assets/Mont/Mont-Hairline.otf") format("opentype");
  font-weight: 100;
  /* Muy ligero */
  font-style: normal;
}

@font-face {
  font-family: "Mont";
  src: url("../assets/Mont/Mont-ExtraLight.otf") format("opentype");
  font-weight: 200;
  /* Extra ligero */
  font-style: normal;
}

@font-face {
  font-family: "Mont";
  src: url("../assets/Mont/Mont-Light.otf") format("opentype");
  font-weight: 300;
  /* Ligero */
  font-style: normal;
}

@font-face {
  font-family: "Mont";
  src: url("../assets/Mont/Mont-Regular.otf") format("opentype");
  font-weight: 400;
  /* Normal */
  font-style: normal;
}

@font-face {
  font-family: "Mont";
  src: url("../assets/Mont/Mont-SemiBold.otf") format("opentype");
  font-weight: 600;
  /* Seminegrita */
  font-style: normal;
}

@font-face {
  font-family: "Mont";
  src: url("../assets/Mont/Mont-Bold.otf") format("opentype");
  font-weight: 700;
  /* Negrita */
  font-style: normal;
}

@font-face {
  font-family: "Mont-Heavy";
  src: url("../assets/Mont/Mont-Heavy.otf") format("opentype");
  font-weight: 800;
  /* Muy pesado */
  font-style: normal;
}

@font-face {
  font-family: "Mont";
  src: url("../assets/Mont/Mont-Black.otf") format("opentype");
  font-weight: 900;
  /* Black */
  font-style: normal;
}

/* Inter font */
@font-face {
  font-family: "Inter";
  src: url("../assets/Inter/Inter-VariableFont_opsz,wght.ttf")
    format("opentype");
  /* Normal */
  font-style: normal;
}

/* Dom font */
@font-face {
  font-family: "Dom";
  src: url("../assets/Dom/dom-bold.ttf")
    format("opentype");
  /* Normal */
  font-style: bold;
}