----------------------------TEMPLATE---------------------------------
<template>
    <div class="w-full sm:max-w-xl bg-white">
        <div class="bg-[#121C26] py-10 px-8">
            <div class="flex justify-center items-center pb-[46px]">
                <img src="/supermat-logo.png" alt="Supermat" class="w-[112px] h-[27px]" />
            </div>
            <HeartFavIcon :class="showHeartFavIcon ? 'visible' : 'invisible'" @click="emit('isOpenHeartFavIcon', true)"
                class="mb-[18px] ms-[8px] cursor-pointer" />
            <div>
                <p v-if="isLoading" style="background: grey; width: 100%"
                    class="animate-pulse text-center bg-gray-200 rounded-full dark:bg-gray-700 max-w-[30%] h-[20px] mb-2.5">
                </p>
                <p v-else style="
            font-size: 10px;
            font-weight: 300;
            line-height: 12.78px;
            text-align: left;
            color: #ffffff;
          ">
                    {{ productoInfo?.sku }}
                </p>

                <p v-if="isLoading" style="background: grey; width: 100%"
                    class="animate-pulse text-center bg-gray-200 rounded-full dark:bg-gray-700 max-w-[100%] h-[40px] mb-2.5">
                </p>
                <p v-else class="mt-[9px] mb-[15px] text-[#FFFFFF]"
                    style="font-weight: 600; font-size: 20.74px; line-height: 26.5px">
                    {{ productoInfo?.nombre }}
                </p>
                <div class="flex w-full justify-between">
                    <!-- <div class="flex justify-start w-full"> -->
                    <button v-if="props.productoInfo.imagenes?.length" @click="showCarousel = true"
                        class="px-1.5 py-1.5 bg-[#66ABD1] rounded-[3.69px]" type="button" style="
              font-weight: 500;
              font-size: 9.85px;
              line-height: 12.58px;
              color: #121c26;
            ">
                        VER FOTOS
                    </button>
                    <!-- </div> -->
                    <!-- <div class="flex justify-end w-full"> -->
                    <button v-if="props.productoInfo.descripcion" @click="showDescription = true"
                        class="px-1.5 py-1.5 text-[#66ABD1] rounded-md" type="button"
                        style="font-weight: 500; font-size: 10.5px; line-height: 13.42px">
                        MAS DETALLES
                    </button>
                    <!-- </div> -->
                </div>
            </div>
        </div>

        <div v-if="showCarousel && props.productoInfo.imagenes.length" class="pt-5 pb-5 w-full">
            <div class="w-full flex px-8">
                <div class="flex flex-col" style="box-shadow: 2px 2px 6.7px 0px rgba(0, 0, 0, 0.1)">
                    <div class="flex justify-end relative">
                        <span @click="showCarousel = false"
                            class="flex items-center justify-center absolute top-[10px] right-[10px] z-10 text-white p-1 rounded-full text-xs w-[29px] h-[29px] text-center bg-[#E15216] cursor-pointer"><svg
                                width="14" height="14" viewBox="0 0 14 14" fill="none"
                                xmlns="http://www.w3.org/2000/svg" class="p-icon p-sidebar-close-icon"
                                aria-hidden="true" data-pc-section="closeicon">
                                <path
                                    d="M8.01186 7.00933L12.27 2.75116C12.341 2.68501 12.398 2.60524 12.4375 2.51661C12.4769 2.42798 12.4982 2.3323 12.4999 2.23529C12.5016 2.13827 12.4838 2.0419 12.4474 1.95194C12.4111 1.86197 12.357 1.78024 12.2884 1.71163C12.2198 1.64302 12.138 1.58893 12.0481 1.55259C11.9581 1.51625 11.8617 1.4984 11.7647 1.50011C11.6677 1.50182 11.572 1.52306 11.4834 1.56255C11.3948 1.60204 11.315 1.65898 11.2488 1.72997L6.99067 5.98814L2.7325 1.72997C2.59553 1.60234 2.41437 1.53286 2.22718 1.53616C2.03999 1.53946 1.8614 1.61529 1.72901 1.74767C1.59663 1.88006 1.5208 2.05865 1.5175 2.24584C1.5142 2.43303 1.58368 2.61419 1.71131 2.75116L5.96948 7.00933L1.71131 11.2675C1.576 11.403 1.5 11.5866 1.5 11.7781C1.5 11.9696 1.576 12.1532 1.71131 12.2887C1.84679 12.424 2.03043 12.5 2.2219 12.5C2.41338 12.5 2.59702 12.424 2.7325 12.2887L6.99067 8.03052L11.2488 12.2887C11.3843 12.424 11.568 12.5 11.7594 12.5C11.9509 12.5 12.1346 12.424 12.27 12.2887C12.4053 12.1532 12.4813 11.9696 12.4813 11.7781C12.4813 11.5866 12.4053 11.403 12.27 11.2675L8.01186 7.00933Z"
                                    fill="currentColor"></path>
                            </svg></span>
                    </div>
                    <Carousel :value="props.productoInfo.imagenes" :numVisible="1" :numScroll="1" :showNavigators="true"
                        showIndicators>
                        <template #item="slotProps">
                            <div class="rounded">
                                <div class="mb-3">
                                    <div class="relative mx-auto">
                                        <img :src="slotProps.data.ImageUrl" :alt="slotProps.data.name"
                                            class="w-full border-round" />
                                    </div>
                                </div>
                            </div>
                        </template>
                    </Carousel>
                </div>
            </div>
        </div>

        <div class="pt-5 pb-5">
            <div class="w-full flex px-8">
                <div class="w-full flex justify-between items-center bg-white">
                    <div class="flex items-center w-full justify-between px-5 py-3 rounded"
                        style="box-shadow: 2px 2px 6.7px 0px rgba(0, 0, 0, 0.1)">
                        <p v-if="isLoading" style="background: grey; width: 100%"
                            class="animate-pulse h-20 bg-gray-200 dark:bg-gray-700 max-w-100 text-center rounded-xl mb-0">
                        </p>
                        <div v-else class="flex items-center justify-between w-full">
                            <p style="
                  font-size: 12px;
                  font-weight: 600;
                  line-height: 15.34px;
                  text-align: center;
                  color: #121c26;
                ">
                                Precio contado
                            </p>
                            <div class="flex flex-col items-end h-20 justify-center">
                                <span v-if="props.productoInfo.precioOferta" class="line-through" style="
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 25.56px;
                    text-align: right;
                    color: #a8a8a8;
                  ">${{
                    Intl.NumberFormat("es-AR", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                    }).format(parseFloat(props.productoInfo.precioVenta).toFixed(2))
                                    }}</span>

                                <span style="
                    font-size: 40.8px;
                    line-height: 36.14px;
                    text-align: right;
                    font-weight: 700;
                    color: #121c26;
                  ">${{
                    !props.productoInfo.precioOferta
                        ? Intl.NumberFormat("es-AR", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        }).format(parseFloat(props.productoInfo.precioVenta).toFixed(2))
                        : Intl.NumberFormat("es-AR", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        }).format(parseFloat(props.productoInfo.precioOferta).toFixed(2))
                                    }}</span>

                                <span v-if="props.productoInfo.precioOferta" style="
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 17.89px;
                    text-align: right;
                    color: #1faf38;
                  ">{{
                    Math.floor(
                        ((props.productoInfo.precioVenta - props.productoInfo.precioOferta) /
                            props.productoInfo.precioVenta) *
                        100
                                    )
                                    }}% OFF</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Sidebar v-model:visible="showDescription" position="right"
            class="rounded-ss-[13px] w-[100vw] md:w-[400px] rounded-es-[13px] relative bg-white"
            style="height: 50vh; font-family: 'Mont', sans-serif">
            <template #header="">
                <h3 style="font-size: 16px;font-weight: 800;line-height: 20.45px;text-align: left;color: #121c26;padding-left: 35px !important; padding-bottom: 10px;" 
                    class="header-carousel ps-[20px] pe-[9px] mt-6"
                >
                    Ficha técnica
                </h3>
            </template>
            <span class="absolute left-[14px] top-[50%] z-10 bg-[#A7A7A7] w-[6px] h-[54px] rounded-full"></span>
            <div class="overflow-auto absolute top-[120px] pe-[25px] ps-[17px]" style="height: 58.1%;white-space: pre-line;">
                <!-- <p v-if="!props.productoInfo.descripcion.includes('html')" style="
                padding-left: 20px;
            font-size: 13px;
            font-weight: 500;
            line-height: 16.61px;
            text-align: left;
            color: #121c26;
          ">
                    {{ props.productoInfo.descripcion }}
                </p> -->
                <p style="
            padding-left: 20px;
            font-size: 13px;
            font-weight: 500;
            line-height: 16.61px;
            text-align: left;
            color: #121c26;
          "  v-html="props.productoInfo.descripcion"></p>
            </div>
        </Sidebar>
    </div>
</template>

---

-------------------------SCRIPT-----------------------------------
<script setup>
import { ref, onMounted } from "vue";
import Carousel from "primevue/carousel";
import Sidebar from "primevue/sidebar";
import HeartFavIcon from "../../assets/icons/HeartFav-Icon";

const emit = defineEmits(["isOpenHeartFavIcon"]);

const showCarousel = ref(false);
const showDescription = ref(false);

const prodUnit = ref("");

const props = defineProps({
  productoInfo: Object,
  isLoading: Boolean,
  showHeartFavIcon: Boolean,
});

onMounted(async () => {
  prodUnit.value = JSON.parse(localStorage.getItem("branchList") || "{}").unit;
});
</script>

----------------------------STYLE-----------------------------------
<style>
.p-sidebar-close.p-sidebar-icon.p-link {
  position: absolute;
  top: 19px;
  right: 15px;
  background-color: #e15216 !important;
  width: 25px;
  height: 25px;
}

.p-sidebar-close.p-sidebar-icon.p-link svg {
  color: white !important;
}

.p-sidebar-header-content {
  overflow: scroll;
  overflow: hidden;
  font-weight: 900;
}

.header-carousel {
  font-weight: 900;
  position: absolute;
  top: 74px;
}

/* FontsCustom */
.font-Mont-weight-400 {
  font-family: "Mont", sans-serif;
  font-weight: 400;
  /* Regular */
}

.font-Mont-weight-500 {
  font-family: "Mont", sans-serif;
  font-weight: 500;
  /* Semi Bold*/
}

.font-Mont-weight-700 {
  font-family: "Mont", sans-serif;
  font-weight: 700;
  /* Bold */
}

.font-Mont-weight-800 {
  font-family: "Mont", sans-serif;
  font-weight: 800;
  /* Heavy */
}

.font-Mont-weight-900 {
  font-family: "Mont", sans-serif;
  font-weight: 900;
  /* Black */
}

.p-carousel-next.p-link,
.p-carousel-prev.p-link {
  display: none;
}

.p-carousel .p-carousel-indicators {
  bottom: 10px;
}

.p-carousel .p-carousel-indicator {
  border-radius: 100%;
  width: 12px;
  height: 12px;
  background-color: gray;
}

.p-carousel .p-carousel-indicator.p-highlight {
  background-color: #66abd1;
}

@media screen and (min-width: 768px) {
  .p-carousel-next.p-link,
  .p-carousel-prev.p-link {
    display: block;
  }
}
</style>
