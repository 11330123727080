<template>
  <DataTable
    class="display cell-border"
    ref="tableRef"
    :data="rows"
    :columns="columns"
    :options="tableOptions"
  >
    <template v-for="slot in Object.keys(slots)" #[slot]="slotProps">
      <slot :name="slot" v-bind="slotProps"></slot>
    </template>
  </DataTable>
</template>
<script setup>
import { ref, useSlots } from "vue";

const tableRef = ref();

const slots = useSlots();

const props = defineProps({
  columns: {
    type: Array,
    default: () => [],
  },
  rows: {
    type: Array,
    default: () => [],
  },
  customConfig: {
    type: Object,
    default: () => ({}),
  },
});

const tableOptions = ref({
  dom: "rtlpB",
  lengthMenu: [
    [10, 20, 50, -1],
    [10, 20, 50, "Todos"],
  ],
  pageLength: 10,
  // displayStart: 10,
  order: [[1, "desc"]],
  responsive: false,
  autoWidth: false,
  // buttons: ['copy', 'csv', 'excel', 'pdf', 'print'],
  language: {
    infoEmpty: "Mostrando del 0 al 0 de 0 registro/s",
    search: "Buscar",
    zeroRecords: "No hay registros para mostrar",
    info: "Mostrando del _START_ al _END_ de un total de _TOTAL_ registro/s",
    next: "Siguiente",
    infoFiltered: "(Filtrados de _MAX_ registros)",
    paginate: {
      first: "Primero",
      previous: "<",
      next: ">",
      last: "Último",
    },
    lengthMenu: "Ver _MENU_ Filas",
  },
  initComplete: function () {
    //   console.log('DataTables has finished its initialisation.', settings, json);
    const csvButton = document.querySelector(".buttons-csv");
    if (csvButton) {
      const container = document.createElement("div");
      container.className = "csv-button-container";
      csvButton.parentNode.insertBefore(container, csvButton);
      container.appendChild(csvButton);
    }
  },
  ...props.customConfig,
});

defineExpose({ tableRef });
</script>
<style>
@import "datatables.net-dt";
@import url("https://cdn.datatables.net/select/1.7.0/css/select.dataTables.min.css");
@import url("https://cdn.datatables.net/1.13.7/css/jquery.dataTables.min.css");

.dt-buttons {
  display: flex;
  width: 100%;
  margin-top: 80px;
  justify-content: flex-start;
  gap: 5px;
}

table.dataTable tr th:nth-child(2) {
  font-size: 15px;
  font-weight: 700;
  line-height: 18.29px;
}

table.dataTable tr th:not(:nth-child(2)) {
  font-size: 15px;
  font-weight: 400;
  line-height: 18.29px;
}

.dataTables_wrapper .dataTables_length select {
  border-radius: 5px;
  border: 1px solid black;
  padding-left: 10px;
}

table.dataTable td {
  font-size: 0.8em;
  border: 1px solid black;
}

table.dataTable {
  border: 1px solid black;
  height: 100%;
}

.page-link.active,
.active > .page-link {
  background-color: var(--bs-secondary-rgb);
}

.page-item .page-link,
.page-item span {
  color: #8392ab;
}

.page-link.active,
.active > .page-link {
  color: #2e3541;
  border: 1px solid #8392ab;
}

a.page-link {
  color: rgb(255, 255, 255);
  border: 1;
}

a.page-item {
  color: #8392ab;
}

.dataTables_wrapper .dataTables_paginate {
  display: flex;
  align-items: center;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: transparent !important;
  border: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 1.2em;
  font-size: 12px;
  border-radius: 5px;
}

/* .dataTables_wrapper .dataTables_paginate .paginate_button.previous {
  font-weight: 400 !important;
} */

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  border: 1px solid black;
  font-size: 22px;
  padding-block: 0;
  padding-inline: 11px;
  font-weight: 600;
}

.page-link:focus {
  color: #2e3541;
}

.page-item .page-link {
  color: #2e3541;
}

.wa-icon {
  color: rgb(70, 192, 70);
}

.wa-icon:hover {
  font-weight: 600;
}

.type-number {
  text-align: right;
}

.card-body .table {
  min-height: 40vh;
}

.form-select {
  background-position: right 0.25rem center !important;
}

.dataTables_length {
  margin-right: 10px;
  margin-top: 25px;
}

.dataTables_length > label {
  margin-top: 5px !important;
  margin-bottom: 0 !important;
}

table.fixedHeader-floating {
  background-color: white;
}

table.fixedHeader-floating.no-footer {
  border-bottom-width: 0;
}

table.fixedHeader-locked {
  position: absolute !important;
  background-color: white;
}

@media print {
  table.fixedHeader-floating {
    display: none;
  }
}

html.dark table.fixedHeader-floating {
  background-color: var(--dt-html-background);
}

html.dark table.fixedHeader-locked {
  background-color: var(--dt-html-background);
}

table.dataTable > tbody > tr.selected > td.select-checkbox:after,
table.dataTable > tbody > tr.selected > th.select-checkbox:after {
  margin-top: -26px !important;
}

/* Selected row color */
table.display.dataTable > tbody > tr.selected > *,
table.display.dataTable > tbody > tr.odd.selected > *,
table.display.dataTable > tbody > tr.selected:hover > * {
  box-shadow: inset 0 0 0 9999px gray !important;
  color: white;
  background-color: #fff;
}

#DataTables_Table_0_wrapper
  table.dataTable.row-border
  > tbody
  > tr.selected
  + tr.selected
  > td,
table.dataTable.display > tbody > tr.selected + tr.selected > td {
  border-top-color: #fff !important;
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
  margin-top: 20px;
  padding-top: 0px;
}

.dataTables_wrapper .dataTables_paginate {
  font-weight: 700;
}
</style>
