<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <!-- component -->
    <div
        class="flex flex-no-wrap h-full w-full"
        style="
            font-family: Montserrat;
            font-size: 15px;
            font-weight: 400;
        "
    >
        <!-- Sidebar starts -->
        <!-- Remove class [ hidden ] and replace [ sm:flex ] with [ flex ] -->
        <!--- more free and premium Tailwind CSS components at https://tailwinduikit.com/ --->
        <SidebarDashboard />
        <!-- Sidebar ends -->
        <!-- Remove class [ h-64 ] when adding a card block -->
        <div class="py-10 w-full px-6 overflow-y-auto max-h-[100vh]">
            <!-- Remove class [ border-dashed border-2 border-gray-300 ] to remove dotted border -->
            <!-- Place your content here -->
            <FormSelectBranchList class="w-full md:w-8/12 lg:w-4/12" />
            <slot />
        </div>
    </div>
</template>
<script setup>
import SidebarDashboard from "./SidebarDashboard.vue";
import FormSelectBranchList from "./FormSelectBranchList.vue";
</script>

<style scoped>
body {
    overflow-y: hidden !important;
}
</style>
