<template>
    <div>
        <div class="flex">
            <button
                @click="showModalPaymentMethod()"
                style="font-size: 15px; font-weight: 400; line-height: 18.29px"
                class="ml-auto mt-3 inline-flex w-full justify-center rounded-[5px] bg-black text-white p-4 shadow-sm ring-1 ring-inset ring-gray-300 sm:mt-0 sm:w-auto"
            >
                AGREGAR NUEVO
            </button>
        </div>
        <div class="w-full mb-5">
            <TableComponent
                id="mediosDePagoTable"
                :rows="rows"
                :columns="columns"
                @draw="drawCallback"
                :custom-config="tableConfig"
            />
        </div>
        <ModalComponent
            :isOpen="isOpen"
            @closeModal="isOpen = false"
            id="paymentMethodModal"
        >
            <template #header>
                <h4 class="font-bold mb-5">
                    {{ `${currentRow.id ? "Editar" : "Nuevo"} Medio de Pago` }}
                </h4>
            </template>
            <form class="w-full" @submit.prevent="onSubmit">
                <div>
                    <label
                        for="medioPago"
                        class="block text-sm font-medium leading-6 text-gray-900"
                        >Medio de pago</label
                    >
                    <div class="mt-2">
                        <input
                            id="medioPago"
                            name="nombre"
                            required
                            autocomplete="off"
                            v-model="currentRow.nombre"
                            class="block w-full rounded-md border-0 py-1.5 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <div class="mt-4">
                    <label
                        for="imagen"
                        class="block text-sm font-medium leading-6 text-gray-900"
                        >Imagen Url</label
                    >
                    <div class="mt-2">
                        <input
                            id="imagen"
                            type="url"
                            placeholder="https://example.com"
                            pattern="https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$"
                            name="url_imagen"
                            v-model="currentRow.url_imagen"
                            class="block w-full rounded-md border-0 py-1.5 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>

                    <div class="mt-4">
                        <label
                            for="sucursales"
                            class="block text-sm font-medium leading-6 text-gray-900"
                            >Sucursales</label
                        >
                        <MultiSelect
                            v-model="selectedBranches"
                            :options="sucursales"
                            optionLabel="nombre"
                            optionValue="id"
                            placeholder="Selecciona una sucursal"
                            display="chip"
                            :loading="loadingSucursales"
                            emptyMessage="No hay sucursales disponibles"
                            class="w-full md:w-20rem ring-1 ring-inset ring-gray-300"
                        />
                    </div>
                </div>
                <input type="submit" hidden ref="inputSubmitRef" />
            </form>
            <template #buttons>
                <div class="flex justify-end gap-x-1 mr-6">
                    <div class="bg-gray-50 py-3">
                        <button
                            type="button"
                            class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            @click="(isOpen = false), (selectedBranches = [])"
                        >
                            Cerrar
                        </button>
                    </div>
                    <div class="bg-gray-50 py-3">
                        <button
                            @click="inputSubmitRef.click()"
                            class="mt-3 inline-flex w-full justify-center rounded-md bg-black text-white px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 sm:mt-0 sm:w-auto"
                        >
                            Guardar
                        </button>
                    </div>
                </div>
            </template>
        </ModalComponent>
    </div>
</template>
<script setup>
import { ref, onMounted, watch } from "vue";
import Notiflix from "notiflix";
import TableComponent from "../components/TableComponent";
import ModalComponent from "../components/ModalComponent";
import MultiSelect from "primevue/multiselect";

const buttons = [
    {
        title: "Report",
        extend: "csvHtml5",
        text: "CSV",
        className: "bg-black text-white rounded p-2 text-sm",
    },
];

const inputSubmitRef = ref();
const currentRow = ref({});
const offsetPagination = ref(0);
const tableRef = ref();
const isOpen = ref(false);
const rows = ref([]);
const selectedBranches = ref([]);
const sucursales = ref([]);
const loadingSucursales = ref(false);
const tableConfig = ref({
    order: [[0, "asc"]],
    select: {
        style: "multi",
        selector: "td:first-child",
    },
    buttons,
    rowId: "id",
});

const columns = ref([
    { data: "id", title: "ID", className: "dt-center" },
    { data: "nombre", title: "Medio de Pago", className: "dt-center" },
    {
        data: "url_imagen",
        title: "Imagen",
        className: "dt-center",
        render: (url_imagen, _, row) => {
            return `<img src='${url_imagen}' style='width: 30px; heigth: 30px; margin-inline: auto' role='button' id='${row.id}' />`;
        },
    },
    {
        data: null,
        title: "Planes asociados",
        className: "dt-center",
        render: (data) => {
            return `<div class='flex justify-center gap-2'>
        <a class="bg-indigo-600 rounded shadow-lg text-white px-2 py-1" href="/planes-de-pago/${data.id}">Ver planes</a>
      </div>`;
        },
    },
    {
        data: null,
        title: "Acciones",
        className: "dt-center",
        render: (data) => {
            return `<div class='flex justify-center gap-2'>
        <i class="fas fa-edit text-indigo-600" role='button' id="edit" data-id="${data.id}"></i>
        <i class="fas fa-trash-alt text-red-600" role='button' id="delete" data-id="${data.id}"></i>
      </div>`;
        },
    },
]);

const drawCallback = (table) => {
    tableRef.value = table.dt.value;
};

const getPaymentsMethod = async () => {
    Notiflix.Block.dots("#mediosDePagoTable", "Cargando datos...");
    try {
        const { data } = await window.axios.get("/api/medios-de-pago", {
            params: {
                limitdsd: 0,
                limitkntreg: offsetPagination.value * 2 || 40,
            },
        });
        rows.value = data.result;
    } catch {
        rows.value = [];
    } finally {
        Notiflix.Block.remove("#mediosDePagoTable");
    }
};

onMounted(() => {
    getPaymentsMethod();
});

const deletePaymentMethod = async (id) => {
    try {
        await window.axios.delete(`/api/medios-de-pago/${id}`);
        rows.value = rows.value.filter(({ id: rowId }) => rowId != id);
        Notiflix.Notify.success("Medio de Pago Eliminado");
    } catch {}
};

const showModaldeletePaymentMethod = (idPaymentMethod) => {
    Notiflix.Confirm.show(
        "¿Estas seguro/a?",
        "Borrar Medio de Pago",
        "Borrar",
        "Cancelar",
        () => deletePaymentMethod(idPaymentMethod),
        () => {},
    );
};

const showModalPaymentMethod = async (idPaymentMethod) => {
    isOpen.value = true;
    if (!sucursales.value.length) await getSucursales();
    if (idPaymentMethod) {
        await getSucursalesByIdMDP(idPaymentMethod);
        const row = tableRef.value.row(`#${idPaymentMethod}`).data();
        currentRow.value = JSON.parse(JSON.stringify(row));
    } else currentRow.value = {};
};

const onSubmit = () => {
    if (!currentRow.value.id) {
        createPaymentMethod();
    } else editPaymentMethod();
};

const createPaymentMethod = async () => {
    try {
        const {
            data: { result },
        } = await window.axios.post("/api/medios-de-pago", {
            ...currentRow.value,
            sucursalesID: selectedBranches.value,
        });
        rows.value = [...rows.value, result];
        currentRow.value = {};
        Notiflix.Block.dots("#paymentMethodModal", "Creando medio de pago");
        isOpen.value = false;
        selectedBranches.value = [];
        Notiflix.Notify.success("Medio de Pago Creado");
    } catch {
        Notiflix.Notify.failure("Error al intentar crear medio de pago");
    } finally {
        Notiflix.Block.remove("#paymentMethodModal");
    }
};

const editPaymentMethod = async () => {
    try {
        Notiflix.Block.dots("#paymentMethodModal", "Editando medio de pago");
        await window.axios.put("/api/medios-de-pago", {
            ...currentRow.value,
            sucursalesID: selectedBranches.value,
        });
        const row = tableRef.value.row(`#${currentRow.value.id}`);
        isOpen.value = false;
        row.data(currentRow.value).draw();
        currentRow.value = {};
        selectedBranches.value = [];
        Notiflix.Notify.success("Medio de Pago Editado");
    } catch {
        Notiflix.Notify.failure("Error al intentar editar medio de pago");
    } finally {
        Notiflix.Block.remove("#paymentMethodModal");
    }
};

watch(tableRef, (table) => {
    table
        .table()
        .on("click", "#delete", (evt) =>
            showModaldeletePaymentMethod(evt.target.dataset.id),
        );
    table
        .table()
        .on("click", "#edit", (evt) =>
            showModalPaymentMethod(evt.target.dataset.id),
        );
});

const getSucursales = async () => {
    loadingSucursales.value = true;
    try {
        const { data } = await window.axios.get("/api/sucursales");
        const result = data.result;

        if (data.status === "ok") {
            sucursales.value = result;
        }
    } catch {
    } finally {
        loadingSucursales.value = false;
    }
};

const getSucursalesByIdMDP = async (id) => {
    try {
        const { data } = await window.axios.get(
            "/api/medios-de-pago/sucursales/" + id,
        );
        const result = data.result;

        if (data.status === "ok" && sucursales.value.length) {
            selectedBranches.value = result.map((suc) => suc.id_sucursal);
        }
    } catch {
        return;
    }
};
</script>

<style>
.csv-button-container {
    display: flex;
    position: relative;
    align-items: center;
    gap: 9px;
}

.csv-button-container::before {
    font-size: 15px;
    font-weight: 400;
    line-height: 18.29px;
    text-align: left;

    content: "Descargar";
    font-size: 0.875rem;
    color: rgb(0, 0, 0);
    display: flex;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 8px 16px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    background-color: black !important;
    color: white !important;
    border: 0;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    background-color: gray !important;
    color: black !important;
}

.dataTables_wrapper .dataTables_filter {
    margin-bottom: 30px !important;
}

.dataTables_wrapper table thead {
    background-color: black !important;
    color: white !important;
}

.dataTables_filter input {
    outline: none;
    border: 1px solid gray !important;
}
</style>
