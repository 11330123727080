<template>
    <div id="qr-code-full-region" />
</template>

<script setup>
import { onMounted, ref } from "vue";
import { Html5Qrcode } from "html5-qrcode";

const emit = defineEmits(["result"]);

const html5QrcodeScanner = ref();

const scanHtml5Qrcode = async (facingMode = { exact: "environment" }) => {
    const config = {
        fps: 5,
        qrbox: 250,
        aspectRatio: 9 / 16,
    };
    try {
        // const [defaultDevice] = await Html5Qrcode.getCameras();
        html5QrcodeScanner.value
            .start(
                {
                    facingMode,
                },
                // defaultDevice?.id,
                config,
                (decodedText, decodedResult) => {
                    emit("result", decodedText, decodedResult);
                },
                () => {
                    // console.log({ errorMessage });
                },
            )
            .catch(() => {
                // handle err
                // console.log({ err });
                scanHtml5Qrcode({ exact: "user" });
            });
    } catch {
        // console.log({ error });
    }
};

const stop = () => {
    const isStarted = html5QrcodeScanner.value.getState();
    if (isStarted === 2) html5QrcodeScanner.value.stop();
};

onMounted(() => {
    html5QrcodeScanner.value = new Html5Qrcode("qr-code-full-region", "render");
});

defineExpose({ scanHtml5Qrcode, stop });
</script>

<style>
#qr-code-full-region img[alt="Info icon"] {
    display: none;
}

#qr-code-full-region {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 86dvh;
}

#qr-code-full-region video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>
