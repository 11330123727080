<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div
        :class="[
            'relative min-h-screen',
            showProduct ? 'bg-white' : 'bg-black',
        ]"
        class="sm:bg-gray-300"
        id="product-detail-container"
    >
        <div
            v-if="isResponseOk"
            class="flex flex-col items-center justify-center w-full"
        >
            <ProductCard_Header
                :productoInfo="productoInfo"
                :isLoading="isLoading"
                @isOpenHeartFavIcon="handleisOpenHeartFavIcon"
                :showHeartFavIcon="showHeartFavIcon"
            />
            <ProductAccordionComponent
                v-if="showProduct"
                :montoAFinanciar="productoInfo.precioVenta"
                :isLoading="isLoading"
            />

            <div class="pt-8 pb-8 w-full sm:max-w-xl bg-white">
                <div class="w-full flex px-8">
                    <div
                        class="w-full flex flex-col justify-between items-center gap-3 bg-white"
                    >
                        <div
                            class="w-full"
                            style="box-shadow: 2px 2px 6.7px 0px #78787840"
                        >
                            <button
                                style="
                                    font-size: 12px;
                                    font-weight: 500;
                                    line-height: 15.34px;
                                    text-align: center;
                                    color: #2f3841;
                                "
                                type="button"
                                @click="showCamScanner"
                                class="py-2 px-18 w-full bg-[#FBFBFB]"
                            >
                                SEGUIR ESCANEANDO
                            </button>
                        </div>

                        <div
                            class="w-full"
                            style="box-shadow: 2px 2px 6.7px 0px #78787840"
                        >
                            <button
                                @click="saveInLIstBtn"
                                style="
                                    font-size: 12px;
                                    font-weight: 500;
                                    line-height: 15.34px;
                                    text-align: center;
                                    color: #ffffff;
                                "
                                type="button"
                                class="py-2 px-18 w-full bg-[#1E87BE]"
                            >
                                GUARDAR EN UNA LISTA
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else id="notfound">
            <div class="notfound">
                <div class="notfound-404">
                    <h2>Producto no encontrado</h2>
                </div>
            </div>
        </div>

        <Sidebar
            v-model:visible="showFormClientToFav"
            position="left"
            class="rounded-ee-[13px] w-[100vw] md:w-[400px] rounded-se-[13px] relative bg-white"
            style="height: 430px; font-family: Mont, sans-serif"
        >
            <span
                class="absolute right-[9px] top-[50%] z-10 bg-[#A7A7A7] w-[6px] h-[54px] rounded-full"
            ></span>
            <div class="overflow-auto absolute top-[57px] pe-[27px] ps-[17px]">
                <form @submit.prevent="saveClientData">
                    <fieldset class="form-fav">
                        <div>
                            <label for="email">
                                <div class="w-full">
                                    Ingresá el
                                    <span style="font-weight: 800">e-mail</span>
                                    a donde querés que mandemos tu lista.
                                </div>
                                <div class="w-full flex">
                                    <div
                                        class="flex w-full"
                                        style="
                                            box-shadow: 2px 2px 6.7px 0px
                                                rgba(0, 0, 0, 0.1);
                                        "
                                    >
                                        <input
                                            v-model="formUser.email"
                                            type="email"
                                            name="email"
                                            id="email"
                                            placeholder="E-MAIL"
                                            required
                                        />
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div>
                            <label for="nombre">
                                <div class="w-full">Nombre</div>
                                <div class="w-full flex">
                                    <div
                                        class="flex w-full"
                                        style="
                                            box-shadow: 2px 2px 6.7px 0px
                                                rgba(0, 0, 0, 0.1);
                                        "
                                    >
                                        <input
                                            v-model="formUser.nombre"
                                            type="text"
                                            name="nombre"
                                            id="nombre"
                                            placeholder="Tu nombre o apodo"
                                            required
                                        />
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div>
                            <label for="telefono">
                                <div>
                                    Número de teléfono
                                    <span
                                        style="
                                            font-size: 10px;
                                            font-weight: 300;
                                            line-height: 12.78px;
                                        "
                                        >(Opcional)</span
                                    >
                                </div>
                                <div class="w-full flex">
                                    <div
                                        class="flex w-full"
                                        style="
                                            box-shadow: 2px 2px 6.7px 0px
                                                rgba(0, 0, 0, 0.1);
                                        "
                                    >
                                        <input
                                            v-model="formUser.telefono"
                                            type="tel"
                                            name="telefono"
                                            id="telefono"
                                            @input="checkValidPhone"
                                            placeholder="TELÉFONO"
                                        />
                                    </div>
                                </div>
                            </label>
                        </div>

                        <div class="w-full sm:max-w-xl bg-white">
                            <div class="w-full flex">
                                <div
                                    class="w-full flex flex-col justify-between items-center gap-[22px] bg-white"
                                >
                                    <div
                                        class="w-full"
                                        style="
                                            box-shadow: 2px 2px 6.7px 0px
                                                #78787840;
                                        "
                                    >
                                        <button
                                            @click="showFormClientToFav = false"
                                            style="
                                                font-size: 12px;
                                                font-weight: 500;
                                                line-height: 15.34px;
                                                text-align: center;
                                                color: #121c26;
                                            "
                                            type="button"
                                            class="py-2 w-full bg-[#FBFBFB]"
                                        >
                                            CERRAR
                                        </button>
                                    </div>

                                    <div
                                        class="w-full"
                                        style="
                                            box-shadow: 2px 2px 6.7px 0px
                                                #78787840;
                                        "
                                    >
                                        <button
                                            style="
                                                font-size: 12px;
                                                font-weight: 500;
                                                line-height: 15.34px;
                                                text-align: center;
                                                color: #ffffff;
                                            "
                                            type="submit"
                                            class="py-2 w-full bg-[#1E87BE]"
                                        >
                                            GUARDAR
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </Sidebar>

        <Sidebar
            v-model:visible="showFavLists"
            position="bottom"
            class="rounded-ss-[13px] w-[100vw] md:w-[400px] rounded-se-[13px] relative bg-white"
            style="height: 369px; font-family: Mont, sans-serif"
        >
            <template #header="">
                <h3
                    style="
                        font-size: 11px;
                        font-weight: 500;
                        line-height: 14.06px;
                        color: #121c26;
                    "
                    class="absolute top-[29px] ps-[0px] pe-[9px]"
                >
                    Hola {{ cookieClientData.nombre }}!
                </h3>
            </template>
            <span
                class="absolute left-[50%] top-[-12px] z-10 bg-[#A7A7A7] w-[6px] h-[54px] rotate-90 rounded-full"
            ></span>
            <div class="relative top-[50px]">
                <div class="w-full flex flex-col justify-between gap-[10px]">
                    <h2
                        style="
                            font-size: 16px;
                            font-weight: 900;
                            line-height: 20.45px;
                        "
                    >
                        Agregar a lista
                    </h2>
                    <div
                        class="overflow-auto w-full flex flex-col justify-between items-start gap-[10px] h-[140px]"
                    >
                        <div
                            class="flex w-full justify-between p-[5px]"
                            v-for="(item, index) in favLists"
                            :key="index"
                        >
                            <div class="flex w-full gap-[10px] items-center">
                                <HeartFavIcon />
                                <span
                                    style="
                                        font-size: 14px;
                                        font-weight: 700;
                                        line-height: 17.89px;
                                    "
                                >
                                    {{ item.nombre }}</span
                                >
                            </div>
                        </div>
                    </div>

                    <div
                        class="w-full flex flex-col justify-between items-center gap-[15px] bg-white"
                    >
                        <div
                            class="w-full"
                            style="box-shadow: 2px 2px 6.7px 0px #78787840"
                        >
                            <button
                                style="
                                    font-size: 12px;
                                    font-weight: 500;
                                    line-height: 15.34px;
                                    color: #ffffff;
                                "
                                type="button"
                                class="py-2 w-full bg-[#0256A3]"
                                @click="
                                    getClientFavListItems(favLists?.[0]?.id)
                                "
                            >
                                VER LISTA
                            </button>
                        </div>

                        <div
                            class="w-full"
                            style="box-shadow: 2px 2px 6.7px 0px #78787840"
                        >
                            <button
                                style="
                                    font-size: 12px;
                                    font-weight: 500;
                                    line-height: 15.34px;
                                    text-align: center;
                                    color: #ffffff;
                                "
                                type="button"
                                class="py-2 w-full bg-[#A8A8A8]"
                                @click="sendToMail"
                            >
                                ENVIAR A MI MAIL
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Sidebar>

        <Sidebar
            v-model:visible="showFavListWithItems"
            position="bottom"
            :showCloseIcon="false"
            class="rounded-es-[13px] w-[100vw] md:w-[400px] rounded-ee-[13px] relative bg-white"
            style="height: 369px; font-family: Mont, sans-serif"
        >
            <!-- <span
                class="absolute left-[50%] bottom-[-12px] z-10 bg-[#A7A7A7] w-[6px] h-[54px] rotate-90 rounded-full"></span> -->
            <div class="relative top-[0px]">
                <div class="w-full flex flex-col justify-between gap-[10px]">
                    <div class="">
                        <div class="flex w-full justify-between p-[5px]">
                            <div class="flex w-full gap-[10px] items-center">
                                <HeartFavIcon />
                                <span
                                    style="
                                        font-size: 14px;
                                        font-weight: 700;
                                        line-height: 17.89px;
                                    "
                                >
                                    {{ listWithItems.nombre }}</span
                                >
                            </div>
                        </div>
                    </div>

                    <div
                        class="overflow-auto w-full flex flex-col items-start gap-[10px] h-[195px] mb-6"
                    >
                        <div
                            class="flex w-full justify-between p-[5px]"
                            v-for="(item, index) in listWithItems.detalles"
                            :key="index"
                        >
                            <div
                                class="flex w-full gap-[10px] justify-between items-center"
                            >
                                <div class="flex flex-col w-full">
                                    <span
                                        style="
                                            font-size: 10px;
                                            font-weight: 800;
                                            line-height: 12.78px;
                                            color: #121c26;
                                        "
                                    >
                                        {{ item.SKU }}</span
                                    >
                                    <span
                                        style="
                                            font-size: 12px;
                                            font-weight: 500;
                                            line-height: 15.34px;
                                            color: #121c26;
                                        "
                                        >{{ item.descripcion }}</span
                                    >
                                </div>
                                <TrashIcon
                                    class="w-[20px] h-[20px] cursor-pointer"
                                    @click="removeItemFromList(item.id)"
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        class="w-full flex flex-col justify-between items-center gap-[15px] bg-white"
                    >
                        <div
                            class="w-full"
                            style="box-shadow: 2px 2px 6.7px 0px #78787840"
                        >
                            <button
                                style="
                                    font-size: 12px;
                                    font-weight: 500;
                                    line-height: 15.34px;
                                    color: #ffffff;
                                "
                                type="button"
                                class="py-2 w-full bg-[#1E87BE]"
                                @click="showFavListWithItems = false"
                            >
                                CERRAR
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Sidebar>

        <Sidebar
            v-model:visible="showScanner"
            position="top"
            :showCloseIcon="true"
            class="rounded-es-[13px] w-[100vw] md:w-[400px] rounded-ee-[13px] relative h-dvh  bg-white"
            style="font-family: Mont, sans-serif"
            id="qr-code-region-container"
        >
            <!-- <span
                class="absolute left-[50%] bottom-[-12px] z-10 bg-[#A7A7A7] w-[6px] h-[54px] rotate-90 rounded-full"></span> -->
            <div class="relative top-[30px]">
                <QRCamScanner
                    ref="qrCamScanner"
                    style="width: 100%"
                    @result="onScan"
                />
            </div>
        </Sidebar>
    </div>
</template>
<script setup>
import { ref, onMounted, watch } from "vue";
import Sidebar from "primevue/sidebar";
import ProductAccordionComponent from "../components/ProductAccordionComponent";
import ProductCard_Header from "../components/ProductCard_Header";
import HeartFavIcon from "../../assets/icons/HeartFav-Icon";
import TrashIcon from "../../assets/icons/Trash";
import Notiflix from "notiflix";
import QRCamScanner from "../components/QR-CamScanner";

const props = defineProps({
    sku: {
        default: "",
        type: String,
    },
});

const qrCamScanner = ref();
const isLoading = ref(false);
const showProduct = ref(true);
const isResponseOk = ref(true);
const productoInfo = ref({});
const showFormClientToFav = ref(false);
const showFavLists = ref(false);
const favLists = ref([]);
const showFavListWithItems = ref(false);
const listWithItems = ref([]);
const isItemInFavList = ref(false);
const cookieClientData = ref("");
const showHeartFavIcon = ref(false);
const isOpenHeartFavIcon = ref(false);
const formUser = ref({
    email: "",
    nombre: "",
    telefono: undefined,
});

const emit = defineEmits(["isLoading"]);

const getProductsDataVtex = async (sku, unit) => {
    try {
        const { data } = await window.axios.get("/api/vtex/product-data", {
            params: { unit: unit, sku: sku },
        });
        return data.result;
    } catch {
        return null;
    }
};

const getProductNameByFamily = async (familia) => {
    try {
        const { data } = await window.axios.post("/api/familias/buscar", {
            familia,
        });
        return data.result;
    } catch {
        return;
    }
};

onMounted(async () => {
    await dataProduct();
    await isSKUInFavList();
    await getClientFavLists();
});

const fetchProductData = async (sku, params) => {
    try {
        const { data } = await window.axios.get("/api/get-product", {
            params: {
                CodAbre: sku,
                ...params,
            },
        });
        return data;
    } catch {
        return null;
    }
};

const fetchFamiliaData = async (familia) => {
    try {
        const familiaData = await getProductNameByFamily(familia);
        return familiaData ? familiaData.descripcion : null;
    } catch {
        return null;
    }
};

const fetchVtexData = async (sku, unit) => {
    try {
        const result = await getProductsDataVtex(sku, unit);
        return result || {};
    } catch {
        return {};
    }
};

const buildProductInfo = (
    productoData,
    familiaNombre,
    descripcion = "",
    imagenes = [],
) => {
    return {
        sku: productoData.SKU,
        nombre: familiaNombre || productoData.nombreProducto,
        precioOferta: productoData.precioOferta || 0,
        precioVenta: parseFloat(productoData.precioVenta).toFixed(2) || "0",
        descripcion: descripcion || "",
        imagenes: imagenes || [],
    };
};

const dataProduct = async () => {
    const params = new URL(window.location.href);
    const parsedParams = Object.fromEntries(params.searchParams);

    isLoading.value = true;

    const productData = await fetchProductData(props.sku, parsedParams);

    if (!productData || productData.status !== "ok") {
        isResponseOk.value = false;
        productoInfo.value = buildProductInfo({}, null);
        isLoading.value = false;
        emit("isLoading");
        showProduct.value = false;
        return;
    }

    const [productoData] = productData.result;
    let productName = "";
    const result = await fetchVtexData(props.sku, parsedParams.unit || "");

    if (!result.nombre) {
        const familiaNombre = await fetchFamiliaData(productoData.familia);
        productName = familiaNombre;
    }

    if (!productName) productName = result.nombre;

    productoInfo.value = buildProductInfo(
        productoData,
        productName || productoData.nombreProducto,
        result.descripcion,
        result.imagenes,
    );

    isResponseOk.value = true;
    isLoading.value = false;
    emit("isLoading");
    if (!Object.keys(productoInfo.value).length) {
        showProduct.value = false;
    }
};

function setCookie(name, data, days) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = `; expires=${date.toUTCString()}`;
    }
    const value = JSON.stringify(data);
    document.cookie = `${name}=${value}${expires}; path=/`;
}

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return JSON.parse(parts.pop().split(";").shift());
    }
    return null;
}

const isSKUInFavList = async () => {
    if (!getCookie("clientData")) return;
    if (!cookieClientData.value)
        cookieClientData.value = getCookie("clientData");
    try {
        const { data } = await window.axios.post("/api/listas/buscarSKU", {
            sku: props.sku,
            id_cliente: cookieClientData.value.id,
        });
        if (data.result) {
            isItemInFavList.value = true;
            showHeartFavIcon.value = true;
        } else {
            showHeartFavIcon.value = false;
            isItemInFavList.value = false;
        }
    } catch {
        return;
    }
};

const saveInLIstBtn = async () => {
    if (!getCookie("clientData")) showFormClientToFav.value = true;
    if (favLists.value.length > 0) {
        showFavLists.value = true;
        if (!isItemInFavList.value)
            await saveItemToList(favLists.value?.[0]?.id);
        else return;
    }
};

const saveClientData = async () => {
    try {
        const { data } = await window.axios.post(
            "/api/clientes",
            formUser.value,
        );
        if (data.status === "ok") {
            Notiflix.Notify.success("Cliente agregado correctamente");

            const dataClient = {
                id: data.result,
                nombre: formUser.value.nombre,
            };
            setCookie("clientData", dataClient);
            cookieClientData.value = dataClient;

            showFormClientToFav.value = false;
            await getClientFavLists();
            showFavLists.value = true;
            if (!isItemInFavList.value)
                await saveItemToList(favLists.value?.[0]?.id);
            else return;
        }
    } catch {
        Notiflix.Notify.failure("Error al agregar cliente");
    }
};

const getClientFavLists = async () => {
    if (!getCookie("clientData")) return;
    if (!cookieClientData.value)
        cookieClientData.value = getCookie("clientData");
    // if(favLists.value.length == 0){
    //     cookieClientData.value = undefined;
    //     document.cookie = 'clientData' + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
    // };

    try {
        const { data } = await window.axios.post("/api/listas/cliente", {
            id_cliente: cookieClientData.value,
        });

        if (data.status === "ok" && data.result.length > 0) {
            favLists.value = data.result;
        } else Notiflix.Notify.failure("No hay listas de favoritos");
    } catch {
        Notiflix.Notify.failure("Error obteniendo lista de favoritos");

        return;
    }
};

const getClientFavListItems = async (id) => {
    try {
        const { data } = await window.axios.post("/api/listas/id", {
            id: id,
        });
        if (data.status === "ok" && data.result.detalles.length > 0) {
            listWithItems.value = data.result;
            showFavListWithItems.value = true;
        } else Notiflix.Notify.failure("No hay items en la lista");
    } catch {
        Notiflix.Notify.failure("Error obteniendo los items de la lista");
        return;
    }
};

const saveItemToList = async (id) => {
    try {
        const { data } = await window.axios.post(
            "/api/listas/agregarProducto",
            {
                id_lista: id,
                item: {
                    sku: productoInfo.value.sku,
                    descripcion: productoInfo.value.nombre,
                },
            },
        );
        if (data.status === "ok") {
            showHeartFavIcon.value = true;
            isItemInFavList.value = true;
            Notiflix.Notify.success(
                "¡El producto se agregó a la lista con éxito!",
            );
        }
    } catch {
        Notiflix.Notify.failure("Error al agregar producto a la lista");
    }
};

const removeItemFromList = async (id) => {
    try {
        const { data } = await window.axios.delete(`/api/listas/item/${id}`);
        if (data.status === "ok") {
            listWithItems.value.detalles = listWithItems.value.detalles.filter(
                (item) => item.id !== id,
            );
            await isSKUInFavList();
            Notiflix.Notify.success(
                "¡El producto se eliminó de la lista con éxito!",
            );
        }
        if (listWithItems.value.detalles.length == 0) {
            showFavListWithItems.value = false;
            showFavLists.value = false;
        }
    } catch {
        Notiflix.Notify.failure("Error al eliminar producto de la lista");
    }
};

const sendToMail = async () => {
    try {
        const { data } = await window.axios.post(
            "/api/listas/enviarListaFavorito",
            {
                id_cliente: cookieClientData.value.id,
            },
        );
        if (data.status === "ok") {
            Notiflix.Notify.success("Lista de favoritos enviada con éxito");
        }
    } catch(error) {
        Notiflix.Notify.failure("Error al enviar lista de favoritos");
    }
};

// watch(showFavLists, async (isOpenFavLists) => {
//     if (isOpenFavLists && !isItemInFavList.value) {
//         await getClientFavLists();
//         await isSKUInFavList();
//         await saveItemToList(favLists.value?.[0]?.id)
//     }
//     else return
// });

watch(showFormClientToFav, (isOpenFormClientToFav) => {
    if (!isOpenFormClientToFav)
        Object.keys(formUser.value).forEach((item) => {
            formUser.value[item] = "";
        });
});

watch(isOpenHeartFavIcon, (isOpen) => {
    if (isOpen) getClientFavListItems(favLists.value?.[0]?.id);
});

watch(showFavListWithItems, async (isOpenFavListWithItems) => {
    if (!isOpenFavListWithItems) isOpenHeartFavIcon.value = false;
});

const handleisOpenHeartFavIcon = (e) => {
    isOpenHeartFavIcon.value = e;
    showFavListWithItems.value = true;
};

const checkValidPhone = (e) => {
    let phone = e.target.value.replace(/\D/g, ""); // Elimina caracteres no numéricos
    if (phone.length > 10) phone = phone.slice(0, 10);
    e.target.value = phone;
};

const showScanner = ref(false);
const showCamScanner = () => {
    showScanner.value = true;

    setTimeout(async () => {
        Notiflix.Block.dots("#qr-code-region-container", "Abriendo camara...");
        await qrCamScanner.value.scanHtml5Qrcode();
        Notiflix.Block.remove("#qr-code-region-container");
    }, 50);

    // scanner.value.start();
};

watch(showScanner, (isOpen) => {
    if (!isOpen) qrCamScanner.value.stop();
});

const onScan = (decodedText, decodedResult) => {
    window.location.href = decodedResult.decodedText;
    // console.log('Texto Decodificado:', decodedText);
    // console.log('Resultado Decodificado:', decodedResult);
};
</script>

----------------------------STYLE-----------------------------------
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap");

#product-detail-container {
    font-family: "Mont", sans-serif;
}

img.logo {
    width: 120px;
    height: auto;
}

.form-fav > div:not(:first-child) {
    margin-top: 22px;
}

.form-fav label > div {
    font-size: 14px;
    font-weight: 500;
    line-height: 17.89px;
    color: #121c26;
    margin-bottom: 9px;
}

.form-fav label div input {
    outline: none;
    border: none;
    width: 100%;
    padding: 10px;
    font-size: 9px;
    font-weight: 400;
    line-height: 11.5px;
    height: 100%;
}

.form-fav label input:focus {
    box-shadow: 0 0 0 2px #262829;
    border-radius: 2px;
}

.form-fav label div input::placeholder {
    opacity: 0.35;
}

#notfound {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #030005;
}

.notfound .notfound-404 {
    position: relative;
    /* height: 180px; */
    margin-bottom: 20px;
}

.notfound .notfound-404 h1 {
    font-family: montserrat, sans-serif;
    font-size: 224px;
    font-weight: 900;
    color: #030005;
    text-transform: uppercase;
    text-shadow:
        -1px -1px 0 #8400ff,
        1px 1px 0 #ff005a;
    letter-spacing: -20px;
}

.notfound-404 h1 {
    height: 270px;
}

.notfound {
    text-align: center;
}

.notfound .notfound-404 h2 {
    font-family: montserrat, sans-serif;
    /* position: absolute; */
    font-size: 42px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    text-shadow: 0 2px 0 #8400ff;
    letter-spacing: 5px;
    margin: 0;
}

.notfound a {
    text-align: center;
    font-family: montserrat, sans-serif;
    display: inline-block;
    text-transform: uppercase;
    color: #ff005a;
    text-decoration: none;
    border: 2px solid;
    background: 0 0;
    padding: 10px 40px;
    font-size: 14px;
    font-weight: 700;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
}

.notfound a:hover {
    color: #8400ff;
}

.fuente {
    position: relative;
    margin-bottom: 20px;
}

.fuente2 {
    font-family: sans-serif;
    position: absolute;
    left: 0;
    right: 0;
    top: 110px;
    font-size: 42px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    text-shadow: 0 2px 0 #8400ff;
    letter-spacing: 13px;
    margin: 0;
}
</style>
