<template>
  <div
    class="flex gap-3 flex-col lg:flex xl:flex-row px-5 xl:px-10 32 xl:gap-10 items-center mt-[10px] p-[30px]"
    style="border: 1px solid rgb(236, 236, 236); box-shadow: 0px 0px 1px 0px"
  >
    <div
      class="container flex flex-wrap gap-3 md:gap-10 items-center justify-center md:justify-start"
    >
      <div>
        <div>
          <label class="block text-sm font-bold leading-6 text-gray-900"
            >Selecciona una tarjeta
          </label>
          <select
            class="mt-2 block w-full rounded-md border-0 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            v-model="selectedCard"
          >
            <option
              v-for="card in props.arrayCards"
              :key="card.id"
              :value="card"
            >
              {{ card.descripcion }}
            </option>
          </select>
        </div>
        <div
          class="mt-5"
          v-if="selectedCard.descripcion.includes('Inmovilizados')"
        >
          <label class="block text-sm font-medium leading-6 text-gray-900"
            >Elegí un título
          </label>
          <select
            class="block rounded-md border-0 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-3 w-full"
            v-model="selectedCard.title"
          >
            <option
              v-for="inmovilizadosTitle in inmovilizadosTitles[
                selectedCard.descripcion
              ]"
              :key="inmovilizadosTitle"
              :value="inmovilizadosTitle"
            >
              {{ inmovilizadosTitle.text }}
            </option>
          </select>
        </div>
      </div>
      <div class="my-3">
        <img :src="selectedCardImageSrc" class="w-[270px] border" alt="" />
      </div>
      <div>
        <div
          id="upload_container"
          class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10"
        >
          <div class="text-center">
            <label
              for="file-upload"
              class="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
            >
              <svg
                v-if="!uploadImagePreview"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#65686B"
              >
                <path
                  d="m3 17 4-4 3.12 3L15 11l6.06 6-1 2H4l-1-2z"
                  fill="#85888C"
                ></path>
                <g>
                  <path
                    d="M19.5 4h-15A2.5 2.5 0 0 0 2 6.5v11A2.5 2.5 0 0 0 4.5 20h15a2.5 2.5 0 0 0 2.5-2.5v-11A2.5 2.5 0 0 0 19.5 4Zm-15 2h15c.28 0 .5.22.5.5v8.09l-4.29-4.29a1 1 0 0 0-1.41 0l-4.29 4.29-2.29-2.29a1 1 0 0 0-1.41 0l-2.29 2.29V6.5c0-.28.22-.5.5-.5Zm15 12h-15a.5.5 0 0 1-.5-.5v-.09l3-3 2.29 2.29a1 1 0 0 0 1.41 0l4.29-4.29 5 5v.09a.5.5 0 0 1-.5.5Z"
                  ></path>
                  <circle cx="9.5" cy="9.5" r="2"></circle>
                </g>
              </svg>
              <img
                v-else
                :src="uploadImagePreview"
                alt=""
                class="w-full h-3/4 object-fit"
              />
              <div class="mt-4 flex text-sm leading-6 text-gray-600">
                <span>Subir imagen</span>
                <input
                  id="file-upload"
                  name="file-upload"
                  ref="fileInput"
                  type="file"
                  class="sr-only"
                  @change="uploadImage"
                />
              </div>
              <p class="text-xs leading-3 text-gray-600">
                PNG, JPG, GIF hasta 10MB
              </p>
            </label>
          </div>
        </div>
      </div>
      <div v-if="selectedCard.showColorInput" class="flex flex-col">
        <label for="colorInput">Elegí un color</label>
        <input
          type="color"
          name="colorInput"
          id="colorInput"
          class="w-full"
          @change="onColorChange"
          :value="computedColor"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";

const uploadImagePreview = ref(null);
const fileInput = ref(null);

const emit = defineEmits([
  "onTicketChange",
  "onUploadedImage",
  "onColorChange",
]);
const props = defineProps({
  selectedColor: { type: String, default: "" },
  arrayCards: { type: Array, default: () => [] },
});

const computedColor = computed(
  () => props.selectedColor || selectedCard.value.defaultColor,
);

const selectedCard = ref(props.arrayCards[0]);
const selectedCardImageSrc = ref(selectedCard.value.path_imagen);

const inmovilizadosTitles = {
  "Ofertas Inmovilizados 1/2": [
    {
      text: "¡REBAJAS DE AMIGO!",
      size: 70,
    },
    {
      text: "¡POR DEBAJO DEL COSTO!",
      size: 55,
    },
    {
      text: "¡ULTIMAS UNIDADES!",
      size: 70,
    },
  ],
  "Ofertas Inmovilizados 1/1": [
    {
      text: "¡REBAJAS DE AMIGO!",
      size: 100,
    },
    {
      text: "¡POR DEBAJO DEL COSTO!",
      size: 80,
    },
    {
      text: "¡ULTIMAS UNIDADES!",
      size: 100,
    },
  ],
};

watch(selectedCard, (selectedCard) => {
  selectedCardImageSrc.value = selectedCard.path_imagen;
  emit("onTicketChange", selectedCard);
  uploadImage();
});

const onColorChange = (e) => {
  emit("onColorChange", e.target.value);
};

const uploadImage = (e) => {
  if (!e || !e.target.files.length) {
    fileInput.value.value = "";
    uploadImagePreview.value = undefined;
    emit("onUploadedImage", undefined);
    return;
  }
  const imageURL = URL.createObjectURL(e.target.files[0]);
  uploadImagePreview.value = imageURL;
  emit("onUploadedImage", e.target.files[0]);
};
</script>

<style scoped>
#upload_container {
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 208px;
  height: 226px;
}

select {
  border-radius: 21px;
}
</style>
