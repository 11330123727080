<template>
  <div class="overflow-hidden relative min-w-[650px] max-w-[750px]">
    <div id="html2canvas" class="absolute"></div>
  </div>

  <div class="flex justify-center">
    <div class="w-full mb-5">
      <ProductPicker class="mb-4" @datatableAddRow="datatableAddRow" />
      <TableComponent
        id="productsTable"
        :rows="rows"
        :columns="columns"
        :custom-config="tableConfig"
        ref="tableComponent"
      />
    </div>
    <ModalComponent :isOpen="isOpen" @closeModal="isOpen = false">
      <div>
        <p class="text-center mb-5"><strong>SKU:</strong> {{ skuRef }}</p>
        <QrcodeVue
          :value="`${baseURL}/producto/${skuRef}?list=${branchList.list}&unit=${branchList.unit}`"
          :size="!isOpen ? 1200 : 300"
          ref="qrRef"
          foreground="#1d1d1b"
          margin="5"
        />
      </div>
    </ModalComponent>
    <ModalBranchList />
  </div>
</template>
<script setup>
import { ref, onMounted, watch, computed, createApp } from "vue";
import TableComponent from "../components/TableComponent";
import ModalComponent from "../components/ModalComponent";
import QrcodeVue from "qrcode.vue";
import Notiflix, { Block } from "notiflix";
import JSZip from "jszip";
import ModalBranchList from "../components/ModalBranchList.vue";
import ProductDetailQR from "../components/ProductDetailQR.vue";
import html2canvas from "html2canvas";
import ProductPicker from "../components/ProductPicker.vue";
import { getFenixProduct } from "../services/getFenixProduct";

const qrRef = ref();
const workbook = ref([]);
const isRowSelected = ref(false);

// eslint-disable-next-line no-undef
const baseURL = computed(() => process.env.MIX_API_URL);

const buttons = ref([
  {
    title: "Report",
    extend: "csvHtml5",
    text: "CSV",
    className: "bg-black text-white rounded p-2 text-sm",
    exportOptions: { orthogonal: "export" },
  },
  {
    text: "Imprimir QRs",
    className: "bg-black text-white rounded p-2 text-sm print-qr hidden",
    attr: {
      id: "print-qr",
    },
    action: async function (_, dt) {
      const selectedRows = dt
        .rows({ selected: true, search: "applied" })
        .data()
        .toArray();
      Notiflix.Block.dots("#app", "Generando QRs...");
      const zip = new JSZip();
      const img = zip.folder("images");

      async function processRow(row) {
        const containerElement = document.getElementById("html2canvas");
        try {
          const data = await getProductsData(row);

          if (!data) {
            // Notiflix.Notify.failure('No se pudo obtener el dato del producto');
            const { familia, SKU, nombreProducto } = row;
            const prodFromExcel = await getProductByFamily(familia);

            createApp(ProductDetailQR, {
              detail: {
                nombre: prodFromExcel || nombreProducto,
                sku: SKU,
                qrString: `${baseURL.value}/producto/${row.SKU}?list=${branchList.value.list}&unit=${branchList.value.unit}`,
              },
            }).mount(containerElement);
          } else {
            createApp(ProductDetailQR, {
              detail: {
                ...data,
                qrString: `${baseURL.value}/producto/${row.SKU}?list=${branchList.value.list}&unit=${branchList.value.unit}`,
              },
            }).mount(containerElement);
          }

          const canvas = await html2canvas(containerElement);

          const imgData = await new Promise((resolve) => {
            canvas.toBlob((blob) => resolve(blob));
          });

          img.file(`${row.SKU}.png`, imgData, { base64: true });
        } catch {
          // console.error("Error al procesar fila:", error);
          Notiflix.Notify.failure("Ocurrió un error al generar QRs");
        }
      }

      async function processRows() {
        for (const row of selectedRows) {
          await processRow(row);
        }

        zip.generateAsync({ type: "blob" }).then(function (content) {
          Notiflix.Block.remove("#app");
          tableRef.value.rows().deselect();
          window.saveAs(content, "images.zip");
        });
      }

      processRows();
      tableRef.value.rows().deselect();
      mandatorySelect.value.checked = false;
    },
  },
]);
const pagination = ref({});
const offsetPagination = ref(0);
const tableConfig = ref({
  select: {
    style: "multi",
    selector: "td:first-child",
  },
  search: {
    return: true,
  },
  buttons: buttons.value,
});

const branchList = ref({});
const tableRef = ref();
const tableComponent = ref();
const mandatorySelect = ref();
const printQrButton = ref();
const isOpen = ref(false);
const skuRef = ref("");
const rows = ref([]);
const columns = ref([
  {
    title: `<input type="checkbox" id="mandatory-select" />`,
    targets: 0,
    data: null,
    defaultContent: "",
    orderable: false,
    className: "select-checkbox dt-head-center",
  },
  { data: "SKU", title: "SKU", className: "dt-center" },
  { data: "nombreProducto", title: "Producto" },
  {
    data: "SKU",
    title: "Slug",
    className: "dt-center",
    render: (sku, type) => {
      if (type === "export")
        // eslint-disable-next-line no-undef
        return `${process.env.MIX_API_URL}/producto/${sku}?list=${branchList.value.list}&unit=${branchList.value.unit}`;
      return `<a href="/producto/${sku}?list=${branchList.value.list}&unit=${branchList.value.unit}" target="_blank" class="text-sky-600">Ver</a>`;
    },
  },
  {
    data: null,
    title: "QR",
    className: "dt-center",
    render: (data) => {
      return `<img src='https://www.imgonline.com.ua/examples/qr-code-url.png' style='width: 30px; heigth: 30px; margin-inline: auto' role='button' id='${data.SKU}' />`;
    },
  },
]);

const openQRModal = async (sku) => {
  isOpen.value = true;
  skuRef.value = sku;
};

const selectAllRows = (evt) => {
  if (printQrButton.value.classList.contains("hidden")) {
    printQrButton.value.classList.remove("hidden");
  } else printQrButton.value.classList.add("hidden");
  if (evt.target.checked) {
    tableRef.value.rows().select();
    return;
  }
  tableRef.value.rows().deselect();
};

const datatableAddRow = ({ newRows, keepRows }) => {
  rows.value = keepRows ? [...rows.value, ...newRows] : newRows;
};

const getProducts = () => {
  Block.dots("#productsTable", "Buscando productos...");
  getFenixProduct({ limitdsd: 0, limitkntreg: 40 })
    .then((newRows) => {
      rows.value = newRows.result;
    })
    .catch((error) => {
      rows.value = [];
      Notiflix.Notify.failure("Ocurrio un error al obtener los productos");
      if (error?.response?.data?.result?.data) {
        Notiflix.Notify.failure("Fenix no funciona");
      }
    })
    .finally(() => Block.remove("#productsTable"));
};

onMounted(() => {
  tableRef.value = tableComponent.value.tableRef.dt;
  mandatorySelect.value = document.getElementById("mandatory-select");
  mandatorySelect.value.addEventListener("click", selectAllRows);
  printQrButton.value = document.getElementById("print-qr");
  branchList.value = JSON.parse(localStorage.getItem("branchList") || "{}");
  getProducts();
});

watch(tableRef, (table) => {
  table.on("click", "img", (evt) => openQRModal(evt.target.id));
  table.on("select deselect", (e, dt) => {
    const items = dt
      .rows({ selected: true, search: "applied" })
      .data()
      .toArray();
    if (items.length && printQrButton.value.classList.contains("hidden")) {
      printQrButton.value.classList.remove("hidden");
    }
    if (!items.length) printQrButton.value.classList.add("hidden");
  });
  table.on("page.dt", () => {
    pagination.value = table.page.info();
    if (
      pagination.value.page + 1 === pagination.value.pages &&
      !table.search() &&
      !workbook.value.length
    ) {
      offsetPagination.value = pagination.value.pages * pagination.value.length;
    }
  });
});

watch(isRowSelected, (isRowSelected) => {
  if (isRowSelected) {
    printQrButton.value.classList.toggle("hidden");
    return;
  }
  printQrButton.value.classList.toggle("hidden");
});

const getProductsData = async (row) => {
  try {
    const { data } = await window.axios.get("/api/vtex/product-data", {
      params: { unit: branchList.value.unit, sku: row.SKU },
    });
    return data.result;
  } catch {
    return;
  }
};

const getProductByFamily = async (familia) => {
  try {
    const { data } = await window.axios.post("/api/familias/buscar", {
      familia,
    });
    return data.result.descripcion;
  } catch {
    return;
  }
};

let debounceGetProducts;
window.onstorage = () => {
  const parsedList = JSON.parse(localStorage.getItem("branchList") || "{}");
  branchList.value = parsedList;
  if (debounceGetProducts) {
    clearTimeout(debounceGetProducts);
    debounceGetProducts = null;
  }
  debounceGetProducts = setTimeout(() => {
    getProducts();
  }, 800);
};
</script>

<style>
.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 8px 16px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background-color: black !important;
  color: white !important;
  border: 0;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background-color: gray !important;
  color: black !important;
}

.dataTables_wrapper .dataTables_filter {
  margin-bottom: 30px !important;
}

.dataTables_wrapper table thead {
  background-color: black !important;
  color: white !important;
}

.dataTables_filter input {
  outline: none;
  border: 1px solid gray !important;
}
</style>
