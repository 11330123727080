----------------------------TEMPLATE---------------------------------
<template>
    <div class="flex flex-col xl:flex-row px-5 sm:px-10 items-center mt-[10px]" id="container-inputs-picker"
        style="border: 1px solid rgb(236, 236, 236); padding: 30px; box-shadow: 0 0 1px 0px"
        :class="[!products.length ? 'gap-[70px]' : 'gap-2']">
        <form class="flex gap-x-5 items-center" @submit.prevent="getProduct">
            <span v-if="selectedOption !== 'EXCEL'" class="p-float-label">
                <InputText
                    class="min-w-[180px] block w-full rounded-md border-0 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    type="text" id="codigo" v-model="code" />
                <label for="codigo">Código</label>
            </span>
            <label v-else for="inputFile"
                class="min-w-[206px] cursor-pointer border-2 border-dashed border-gray-200 rounded-md px-3 py-1.5 w-full md:w-auto text-center text-nowrap"
                :class="{
                    'border-green-600': !!workbook.length,
                    'text-green-600': !!workbook.length,
                    'font-bold': !!workbook.length,
                }">
                {{ workbook.length ? `${workbook.length} productos` : "Cargar Archivo" }}
                <input class="min-w-[180px]" type="file" @change="uploadExcel" id="inputFile" hidden
                    :disabled="loading" />
            </label>
            <fieldset class="flex flex-col mt-5 md:mt-0">
                <label class="font-semibold">
                    <input type="radio" v-model="selectedOption" value="CodAbre" :disabled="loading" />
                    SKU
                </label>

                <label class="font-semibold" style="text-wrap: nowrap">
                    <input type="radio" v-model="selectedOption" value="CodFam" :disabled="loading" />
                    Familia
                </label>
                <label class="font-semibold" style="text-wrap: nowrap">
                    <input type="radio" v-model="selectedOption" value="EXCEL" :disabled="loading" />
                    Excel
                </label>
            </fieldset>
        </form>
        <Button @click="getProduct" :label="selectedOption == 'CodAbre' ? 'AGREGAR' : 'BUSCAR'"
            class="w-full min-w-[100px] max-w-[100px] md:w-[200px] rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            severity="danger" raised :loading="loading" :disabled="loading" />

        <div class="flex flex-col xl:flex-row items-center justify-center gap-5">
            <div v-if="!!products.length"
                class="flex flex-col md:flex-row items-center w-full min-w-[180px] max-w-[480px] border-2 border-gray-200 rounded-md outline-none mt-3 mb-3">
                <MultiSelect v-model="selectedProducts" :options="products" filter
                    :optionLabel="(data) => `${data.SKU} - ${data.nombreProducto}`" display="chip" resetFilterOnHide
                    :filterFields="['SKU', 'nombreProducto']" :loading="loading" placeholder="Productos"
                    class="w-full" />
            </div>

            <div v-if="!!selectedProducts.length && !!products.length" class="mt-5 md:mt-0 md:w-fit">
                <Button @click="addMultipleRows" label="AGREGAR " icon="fas fa-list"
                    class="rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                    raised />
            </div>
        </div>
        <div v-if="!!skusWithErrors.length && !workbook.length" class="mt-5 md:mt-0 w-full md:w-fit">
            <Button @click="getProduct" :label="`Reintentar ${skusWithErrors.length}`"
                class="rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                raised />
        </div>
    </div>
</template>
<script setup>
import { ref, watch, getCurrentInstance, onMounted } from "vue";
import MultiSelect from "primevue/multiselect";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Notiflix, { Block } from "notiflix";
import "primevue/resources/themes/bootstrap4-light-blue/theme.css";
import * as XLSX from "xlsx";
import { formatRows } from "../utils/formatRows";
import { getFenixProduct } from "../services/getFenixProduct";

const code = ref(null);
const selectedOption = ref("CodAbre");
const selectedProducts = ref([]);
const loading = ref(false);
const products = ref([]);
const workbook = ref([]);
const skusWithErrors = ref([]);
const excelHandler = ref(false);
const emit = defineEmits(["addRow", "updateRow", "datatableAddRow"]);

const uploadExcel = (e) => {
    const file = e.target.files[0];
    if (!file) {
        workbook.value = [];
        e.target.value = '';
        return;
    }
    const reader = new FileReader();
    reader.onload = function (e) {
        const workbookReaded = XLSX.read(e.target.result, { type: "array" });
        const sheet = XLSX.utils.sheet_to_json(
            workbookReaded.Sheets[workbookReaded.SheetNames[0]]
        );
        workbook.value = sheet
            .filter(({ SKU }) => !!SKU)
            .filter(({ SKU }, index, arr) => {
                // remove duplicated SKUs
                return arr.findIndex(({ SKU: sku }) => sku === SKU) === index;
            });
        e.target.value = '';
        Notiflix.Notify.info(`${workbook.value.length} filas cargadas con éxito`, {
            position: "right-top",
        });
    };
    reader.readAsArrayBuffer(file);
};

const getProduct = async () => {
    if (loading.value) return;
    loading.value = true;
    Block.dots("#container-inputs-picker", "Buscando SKUs...");
    let keepRows = false;
    
    if (skusWithErrors.value.length) {
        keepRows = true;
        workbook.value = skusWithErrors.value;
        skusWithErrors.value = [];
    };

    if (selectedOption.value == "EXCEL" && workbook.value.length) {
        const formatedWork = workbook.value.map(({ SKU }) => ({ SKU }));
        emit("addRow", formatedWork);
        workbook.value.forEach(({ SKU }, index) => {
            getFenixProduct({code: { CodAbre: SKU }})
            .then(({result}) => {
                const formatedRow = formatRows(result);
                
                emit("updateRow", index, formatedRow);
                // newRows = [...newRows, ...formatedRow];
                emit("datatableAddRow", {newRows: result,  keepRows: keepRows || index});
            })
            .catch(() => {
                skusWithErrors.value = [...skusWithErrors.value, { SKU }];
            })
            .finally(() => {
                if (index + 1 === workbook.value.length) {
                    loading.value = false;
                    Block.remove("#container-inputs-picker");
                    workbook.value = [];
                }
            });
        });
        return;
    }

    if (!code.value) {
        loading.value = false;
        Block.remove("#container-inputs-picker");
        return
    };
    
    const {result} = await getFenixProduct({ code: {[selectedOption.value]: code.value} })
    .catch((error) => {
        Notiflix.Notify.failure("Ocurrio un error al obtener el producto " + code.value);
      if (error?.response?.data?.result?.data) {
        Notiflix.Notify.failure("Fenix no funciona");
      }
    })
    .finally(() => {
        loading.value = false;
        Block.remove("#container-inputs-picker");
    });
    
    if(selectedOption.value === "CodFam") {
        products.value = result
        return
    }
    
    const formatedRow = formatRows(result);
    emit("addRow", formatedRow);
    emit("datatableAddRow", {newRows: formatedRow, keepRows});
    
};

const addMultipleRows = () => {
    const formatedRows = formatRows(selectedProducts.value)

    emit("addRow", formatedRows);
    emit("datatableAddRow", {newRows: formatedRows});

    Notiflix.Notify.success(`Productos cargados correctamente`);
    products.value = [];
    selectedProducts.value = [];
};

onMounted(() => {
    // console.log({instance: getCurrentInstance()});
    excelHandler.value = !!getCurrentInstance().vnode.props.onExcelHandler
})

watch(selectedOption, () => {
    workbook.value = [];
});
</script>

---

-------------------------STYLE-----------------------------------
<style>
.p-multiselect-label-container {
  /* width: 350px !important; */
  max-height: 105px !important;
  transition: all 2s;
}

.p-multiselect-label-container:hover {
  overflow-y: auto;
}

.p-multiselect-label {
  gap: 2px;
  display: flex !important;
  flex-wrap: wrap !important;
  white-space: wrap;
}

.p-checkbox .p-checkbox-input:not(:checked) + .p-checkbox-box {
  background-color: rgb(192, 192, 192) !important;
}

.p-checkbox .p-checkbox-input:checked + .p-checkbox-box {
  background-color: rgb(88, 80, 236) !important;
}
.p-multiselect-label-container {
  width: 100% !important;
}
</style>
