<!-- eslint-disable no-undef -->
<template>
  <div class="mt-3" id="preview-container">
    <Button
      :disabled="!props.rows.length"
      @click="handlePrint()"
      label="Imprimir"
      class="rounded-md bg-indigo-600 my-3 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      severity="danger"
      raised
    />
    <Button
      :disabled="!props.rows.length"
      @click="showPreview"
      label="Ver Preview"
      class="ml-2 rounded-md bg-indigo-600 my-3 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      severity="danger"
      raised
    />
    <div ref="pdfContainer" style="font-family: Inter; line-height: initial">
      <embed
        v-show="!!pdfUrlEmbed"
        :src="pdfUrlEmbed"
        type="application/pdf"
        width="100%"
        height="100%"
        style="min-height: 842px"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import Button from "primevue/button";
import jsPDF from "jspdf";
// import Inter from "../../assets/Inter/Inter_24pt-Regular.ttf";
// import InterBold from "../../assets/Inter/Inter_24pt-Bold.ttf";
// import Dom from "../../assets/Dom/dom-bold.ttf";
import { Inter, InterBold, Dom } from "../../assets/Fonts/Fonts.js";
import { Block } from "notiflix";
import { RegularTicket2 } from "./precieroOfertas/regular-2";

const pdfUrlEmbed = ref("");

const props = defineProps({
  rows: { type: Array, default: () => [] },
  uploadedImage: { type: File || undefined },
  selectedColor: { type: String },
  selectedTicket: { type: Object },
});

const chunk = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size),
  );

const pdfContainer = ref();

const showPreview = () => {
  handlePrint(true);
};

function htmlPage(document, pages, index = 0, preview) {
  const hasPages = pages.length > 0;

  if (!hasPages) {
    Block.remove("#preview-container");
    if (preview) {
      return (pdfUrlEmbed.value = URL.createObjectURL(document.output("blob")));
    }
    if (window.location.hostname == "localhost")
      window.open(document.output("bloburl"), "_blank");
    // pdfUrlEmbed.value = URL.createObjectURL(document.output("blob"));
    else {
      document.save();
      pdfUrlEmbed.value = "";
    }
    return;
  }

  const [page, ...restPages] = pages;

  const products = chunk(page, props.selectedTicket.internalLimit);

  products.forEach(([product, product2], productIndex) => {
    const image = props.uploadedImage
      ? URL.createObjectURL(props.uploadedImage)
      : props.selectedTicket.img_logo;

    product.image = image;
    if (product2) product2.image = image;

    const Y_POSITION = props.selectedTicket.Y_POSITION(productIndex);
    const FILLED_WHITE_RECT_Y_POSITION =
      props.selectedTicket.FILLED_WHITE_RECT_Y_POSITION(productIndex);

    RegularTicket2(document, product, product2, {
      ...props.selectedTicket,
      Y_POSITION,
      FILLED_WHITE_RECT_Y_POSITION,
    });
  });
  if (!index || (index && pages.length > 1)) {
    document.addPage("a4", props.selectedTicket.pdfOrientation || "p");
  }
  return htmlPage(document, restPages, index + 1, preview);
}

// function convertFontToBase64(fontUrl) {  //cuando se cargue el font de public
//   return fetch(fontUrl)
//     .then((response) => response.blob())
//     .then((blob) => {
//       return new Promise((resolve, reject) => {
//         const reader = new FileReader();
//         reader.onloadend = () => resolve(reader.result.split(",")[1]); // Extract the base64 part
//         reader.onerror = reject;
//         reader.readAsDataURL(blob);
//       });
//     });
// }

const handlePrint = async (preview = false) => {
  Block.dots("#preview-container", "Generando PDF...");
  const pdf = new jsPDF(props.selectedTicket.pdfOrientation || "p", "mm", "a4");

  // const base64Inter = await convertFontToBase64(Inter);
  // const base64InterBold = await convertFontToBase64(InterBold);
  // const base64Dom = await convertFontToBase64(Dom);
  // let doc = new jsPDF("l", "mm", "a4");
  pdf.addFileToVFS("Inter.ttf", Inter);
  pdf.addFileToVFS("InterBold.ttf", InterBold);
  pdf.addFont("Inter.ttf", "Inter", "normal");
  pdf.addFont("InterBold.ttf", "Inter", "bold");
  // pdf.setFont("Inter", "normal");

  pdf.addFileToVFS("Dom.ttf", Dom);
  pdf.addFont("Dom.ttf", "Dom", "bold");
  // pdf.setFont("Dom", "bold");

  // pdf.addFont("http://localhost:8000/fonts/dom-bold.ttf", "Dom", "bold");
  // pdf.setFont("Dom", "bold");

  // doc.addFileToVFS("Dom.ttf", base64Dom);
  // doc.addFont("Dom.ttf", "Dom");
  const rows = props.rows.filter((row) => row.nombreProducto);
  const pages = chunk(rows, props.selectedTicket.pageLimit);
  htmlPage(pdf, pages, 0, preview);
};

// watchEffect(() => {
//   handlePrint();
// });
</script>
<style>
.cross-out-price {
  position: relative;
}

.cross-out-price::before {
  position: absolute;
  content: "";
  left: 0;
  top: 75%;
  right: 0;
  border-top: 6px solid;
  border-color: rgb(224 36 36 / 1);
}
</style>
