<template>
  <div>
    <button
      aria-label="toggle sidebar"
      class="md:hidden h-10 w-10 z-10 bg-gray-800 absolute mt-2 flex items-center shadow rounded-tr rounded-br justify-center cursor-pointer focus:outline-none rounded focus:ring-gray-800"
      @click="sidebarHandler"
      style="margin-left: calc(100vw - 50px)"
    >
      <svg
        v-if="!isOpen"
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-adjustments"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#FFFFFF"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <circle cx="6" cy="10" r="2" />
        <line x1="6" y1="4" x2="6" y2="8" />
        <line x1="6" y1="12" x2="6" y2="20" />
        <circle cx="12" cy="16" r="2" />
        <line x1="12" y1="4" x2="12" y2="14" />
        <line x1="12" y1="18" x2="12" y2="20" />
        <circle cx="18" cy="7" r="2" />
        <line x1="18" y1="4" x2="18" y2="5" />
        <line x1="18" y1="9" x2="18" y2="20" />
      </svg>

      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-x text-white"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <line x1="18" y1="6" x2="6" y2="18" />
        <line x1="6" y1="6" x2="18" y2="18" />
      </svg>
    </button>
    <aside
      ref="sidebar"
      class="w-[280px] z-10 translate-x-[-280px] md:translate-x-0 absolute sm:relative bg-[#414141] shadow overflow-y-auto h-screen flex-col justify-between flex transition duration-150 ease-in-out"
    >
      <div class="px-8">
        <div class="h-16 w-full flex items-center justify-center mt-[50px]">
          <img :src="logoUniber" alt="Supermat Logo" class="object-contain" />
        </div>
        <ul
          class="mt-[145px]"
          style="
            font-size: 16px;
            font-weight: 700;
            line-height: 19.5px;
            letter-spacing: 0.37em;
          "
        >
          <li
            class="flex w-full justify-between text-gray-300 hover:text-gray-100 cursor-pointer items-center pb-[119px]"
          >
            <a
              href="/home"
              class="flex items-center"
              :class="{ active: activeLocation('/home') }"
            >
              <Home-Icon class="w-6 h-6 me-5" />
              <span>INICIO</span>
            </a>
            <!-- <div class="py-1 px-3 bg-gray-600 rounded text-gray-300 flex items-center justify-center text-xs">5</div> -->
          </li>
          <li
            class="flex w-full justify-between text-gray-300 hover:text-gray-100 cursor-pointer items-center pb-[100px]"
          >
            <a
              href="/medios-de-pago"
              class="flex items-center"
              :class="{
                active: activeLocation('/medios-de-pago'),
              }"
            >
              <WalletIcon class="w-6 h-6 me-5" />
              <span
                >MEDIOS <br />
                DE PAGO</span
              >
            </a>
            <!-- <div class="py-1 px-3 bg-gray-600 rounded text-gray-300 flex items-center justify-center text-xs">8</div> -->
          </li>

          <!-- <li class="flex w-full justify-between text-gray-300 hover:text-gray-100 cursor-pointer items-center mb-6">
            <a href="/preciero"
              class="flex items-center"
              :class="{ active: activeLocation('/preciero') }">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-compass" width="18"
                height="18" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none"
                stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z"></path>
                <polyline points="8 16 10 10 16 8 14 14 8 16"></polyline>
                <circle cx="12" cy="12" r="9"></circle>
              </svg>
              <span >Preciero</span>
            </a>
          </li> -->

          <!-- <li class="flex w-full justify-between text-gray-300 hover:text-gray-100 cursor-pointer items-center mb-6">
            <a href="javascript:void(0)" class="flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-code" width="20" height="20"
                viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round"
                stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z"></path>
                <polyline points="7 8 3 12 7 16"></polyline>
                <polyline points="17 8 21 12 17 16"></polyline>
                <line x1="14" y1="4" x2="10" y2="20"></line>
              </svg>
              <span >Deliverables</span>
            </a>
          </li>
          <li class="flex w-full justify-between text-gray-300 hover:text-gray-100 cursor-pointer items-center mb-6">
            <a href="javascript:void(0)" class="flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-puzzle" width="18" height="18"
                viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round"
                stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z"></path>
                <path
                  d="M4 7h3a1 1 0 0 0 1 -1v-1a2 2 0 0 1 4 0v1a1 1 0 0 0 1 1h3a1 1 0 0 1 1 1v3a1 1 0 0 0 1 1h1a2 2 0 0 1 0 4h-1a1 1 0 0 0 -1 1v3a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-1a2 2 0 0 0 -4 0v1a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1h1a2 2 0 0 0 0 -4h-1a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1">
                </path>
              </svg>
              <span >Invoices</span>
            </a>
            <div class="py-1 px-3 bg-gray-600 rounded text-gray-300 flex items-center justify-center text-xs">25</div>
          </li>
          <li class="flex w-full justify-between text-gray-300 hover:text-gray-100 cursor-pointer items-center mb-6">
            <a href="javascript:void(0)" class="flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-stack" width="18" height="18"
                viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round"
                stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" />
                <polyline points="12 4 4 8 12 12 20 8 12 4" />
                <polyline points="4 12 12 16 20 12" />
                <polyline points="4 16 12 20 20 16" />
              </svg>
              <span >Inventory</span>
            </a>
          </li>
          <li class="flex w-full justify-between text-gray-300 hover:text-gray-100 cursor-pointer items-center">
            <a href="javascript:void(0)" class="flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-settings" width="18" height="18"
                viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round"
                stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" />
                <path
                  d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                <circle cx="12" cy="12" r="3" />
              </svg>
              <span >Settings</span>
            </a>
          </li> -->
        </ul>
      </div>

      <div class="px-8">
        <ul
          class="mt-12"
          style="font-size: 14px; font-weight: 400; line-height: 17.07px"
        >
          <li
            class="flex w-full justify-between text-gray-300 hover:text-gray-100 cursor-pointer items-center pb-[35px]"
          >
            <a href="#" class="flex items-center">
              <Registros-Icon class="w-6 h-6 me-5" />
              <span>REGISTRO</span>
            </a>
            <!-- <div class="py-1 px-3 bg-gray-600 rounded text-gray-300 flex items-center justify-center text-xs">5</div> -->
          </li>
        </ul>
      </div>

      <div class="pl-8 mt-auto">
        <ul
          style="
            font-size: 14px;
            font-weight: 300;
            line-height: 17.07px;
            letter-spacing: 0.28em;
          "
        >
          <li
            class="flex w-full justify-between text-gray-300 hover:text-gray-100 cursor-pointer items-center pb-[35px]"
          >
            <a href="#" class="flex items-center">
              <DownloadIcon class="w-6 h-6 me-5" />
              <span @click="tutorialByActualRoute">TUTORIAL</span>
            </a>
            <!-- <div class="py-1 px-3 bg-gray-600 rounded text-gray-300 flex items-center justify-center text-xs">5</div> -->
          </li>
          <li
            class="flex w-full justify-between text-gray-300 hover:text-gray-100 cursor-pointer items-center pb-[60px]"
          >
            <a href="#" class="flex items-center">
              <HeadPhoneIcon class="w-6 h-6 me-5" />
              <span>SOPORTE</span>
            </a>
            <!-- <div class="py-1 px-3 bg-gray-600 rounded text-gray-300 flex items-center justify-center text-xs">8</div> -->
          </li>

          <li
            class="flex w-full text-gray-300 hover:text-gray-100 cursor-pointer items-center pb-[50px]"
            @click="logout"
          >
            <i>
              <ArrowLeftIcon class="w-6 h-6 me-5" />
            </i>
            <span>SALIR</span>
            <!-- </a> -->
            <!-- <div class="py-1 px-3 bg-gray-600 rounded text-gray-300 flex items-center justify-center text-xs">8</div> -->
          </li>
        </ul>
      </div>
      <!-- <div class="px-8 border-t border-gray-700">
        <ul class="w-full flex items-center justify-between bg-gray-800">
          <li class="cursor-pointer text-white pt-5 pb-3">
            <button aria-label="show notifications" class="focus:outline-none focus:ring-2 rounded focus:ring-gray-300">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-bell" width="20" height="20"
                viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round"
                stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z"></path>
                <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6">
                </path>
                <path d="M9 17v1a3 3 0 0 0 6 0v-1"></path>
              </svg>
            </button>
          </li>
          <li class="cursor-pointer text-white pt-5 pb-3">
            <button aria-label="open chats" class="focus:outline-none focus:ring-2 rounded focus:ring-gray-300">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-messages" width="20" height="20"
                viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round"
                stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z"></path>
                <path d="M21 14l-3 -3h-7a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1h9a1 1 0 0 1 1 1v10"></path>
                <path d="M14 15v2a1 1 0 0 1 -1 1h-7l-3 3v-10a1 1 0 0 1 1 -1h2"></path>
              </svg>
            </button>
          </li>
          <li class="cursor-pointer text-white pt-5 pb-3">
            <button aria-label="open settings" class="focus:outline-none focus:ring-2 rounded focus:ring-gray-300">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-settings" width="20" height="20"
                viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round"
                stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z"></path>
                <path
                  d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z">
                </path>
                <circle cx="12" cy="12" r="3"></circle>
              </svg>
            </button>
          </li>
          <li class="cursor-pointer text-white pt-5 pb-3">
            <button aria-label="open logs" class="focus:outline-none focus:ring-2 rounded focus:ring-gray-300">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-archive" width="20" height="20"
                viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round"
                stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z"></path>
                <rect x="3" y="4" width="18" height="4" rx="2"></rect>
                <path d="M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-10"></path>
                <line x1="10" y1="12" x2="14" y2="12"></line>
              </svg>
            </button>
          </li>
        </ul>
      </div> -->
    </aside>
  </div>
</template>
<script setup>
import { computed, ref } from "vue";
import logoUniber from "../../assets/images/logo-uniber.png";
import HomeIcon from "../../assets/icons/Home-Icon.vue";
import ArrowLeftIcon from "../../assets/icons/ArrowLeft-Icon.vue";
import WalletIcon from "../../assets/icons/Wallet-Icon.vue";
import DownloadIcon from "../../assets/icons/Download-Icon.vue";
import HeadPhoneIcon from "../../assets/icons/HeadPhone.vue";
import RegistrosIcon from "../../assets/icons/Registros-Icon.vue";
import { logout } from "../utils/logout.js";

const isOpen = ref(false);
const sidebar = ref();

const sidebarHandler = () => {
  isOpen.value = !isOpen.value;
  sidebar.value.style.transform = `translateX(${isOpen.value ? "0px" : "-316px"})`;
};

const activeLocation = (path) =>
  computed(() => window.location.pathname === path).value;

const tutorialByActualRoute = () => {
  if (activeLocation("/qr")) {
    window.open("/pdf/Instructivo_QR_V1.pdf", "_blank");
  } else if (activeLocation("/preciero")) {
    window.open("/pdf/Instructivo_PRECIERO_V1.pdf", "_blank");
  }
  return;
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap");

/* li span{
  color:white
} */

.active {
  /* text-decoration: underline; */
  color: white;
}

*::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 1);
  background-clip: padding-box;
}
</style>
